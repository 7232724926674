import Vue from 'vue';
// eslint-disable-next-line no-unused-vars
import Vuex, { Store } from 'vuex';
import { indexTypes as types } from './action-types';
import modules from './modules';

Vue.use(Vuex);

export interface State {
    common: {
        user: {},
        uacMenusMap: {}, //uac菜单/按钮map
        sideListVisible: boolean,
    }
}

const actions = {
    // async getUserInfo(
    //     context: { commit: Commit, dispatch: Dispatch},
    // ): Promise<ApiResponse.MDPDataError> {
    //     const url = '/mc-api/v1/userinfo';
    //     const { error, data }: ApiResponse.MDPData = await request.get(url);
    //     if (!error) {
    //         context.commit(types.GET_USER_INFO, data);
    //         context.dispatch('getAreaNumberList');
    //         context.dispatch('getCommonEnum');
    //         // 设置userId，用于统计uv
    //         window.localStorage.setItem('userId', data.id);
    //     }
    //     return error;
    // }
};

const MENU_ID = {
    AGENT_CREATE: 4,
    AGENT_SEARCH: 5,
    AGENT_BENEFIT_CHANGE: 6,
    EMP_SEARCH: 7,
    EMP_ADD: 8,
    EMP_EDIT: 10,
    EMP_RESET: 11,
    POI_TYPE_SEARCH: 16,
    POI_TYPE_ADD: 17,
    POI_TYPE_EDIT: 18,
    POI_TYPE_DISABLE: 19,
    POI_SEARCH: 20,
    POI_EXPORT: 21,
    POI_DETAIL: 22,
    ACCOUNT_SEARCH: 23,
    ACCOUNT_EDIT: 24,
    ACCOUNT_RESET_PASSWORD: 25,
    ROLE_ADD: 28,
    ROLE_EDIT: 29,
    ROLE_PERMIT: 30,
    ORDER_CLOSE: 41, // 关单 暂停计费 退款 人工还宝
    ORDER_SUSPEND: 42,
    ORDER_REFUND: 43,
    ORDER_MANRETURN: 44,

};

const mutations = {
    [types.GET_USER_INFO](state: State, payload: any) {
        state.common.user = payload;
    },
    [types.GET_UAC_MENUS](state: State, payload: any) {
        const AGENT_CREATE = payload.indexOf(MENU_ID.AGENT_CREATE) > -1;
        const AGENT_SEARCH = payload.indexOf(MENU_ID.AGENT_SEARCH) > -1;
        const AGENT_BENEFIT_CHANGE = payload.indexOf(MENU_ID.AGENT_BENEFIT_CHANGE) > -1;
        const EMP_SEARCH = payload.indexOf(MENU_ID.EMP_SEARCH) > -1;
        const EMP_ADD = payload.indexOf(MENU_ID.EMP_ADD) > -1;
        const EMP_EDIT = payload.indexOf(MENU_ID.EMP_EDIT) > -1;
        const EMP_RESET = payload.indexOf(MENU_ID.EMP_RESET) > -1;
        const POI_TYPE_SEARCH = payload.indexOf(MENU_ID.POI_TYPE_SEARCH) > -1;
        const POI_TYPE_ADD = payload.indexOf(MENU_ID.POI_TYPE_ADD) > -1;
        const POI_TYPE_EDIT = payload.indexOf(MENU_ID.POI_TYPE_EDIT) > -1;
        const POI_TYPE_DISABLE = payload.indexOf(MENU_ID.POI_TYPE_DISABLE) > -1;
        const POI_SEARCH = payload.indexOf(MENU_ID.POI_SEARCH) > -1;
        const POI_EXPORT = payload.indexOf(MENU_ID.POI_EXPORT) > -1;
        const POI_DETAIL = payload.indexOf(MENU_ID.POI_DETAIL) > -1;
        const ACCOUNT_SEARCH = payload.indexOf(MENU_ID.ACCOUNT_SEARCH) > -1;
        const ACCOUNT_EDIT = payload.indexOf(MENU_ID.ACCOUNT_EDIT) > -1;
        const ACCOUNT_RESET_PASSWORD = payload.indexOf(MENU_ID.ACCOUNT_RESET_PASSWORD) > -1;
        const ROLE_ADD = payload.indexOf(MENU_ID.ROLE_ADD) > -1;
        const ROLE_EDIT = payload.indexOf(MENU_ID.ROLE_EDIT) > -1;
        const ROLE_PERMIT = payload.indexOf(MENU_ID.ROLE_PERMIT) > -1;
        const ORDER_CLOSE = payload.indexOf(MENU_ID.ORDER_CLOSE) > -1;
        const ORDER_SUSPEND = payload.indexOf(MENU_ID.ORDER_SUSPEND) > -1;
        const ORDER_REFUND = payload.indexOf(MENU_ID.ORDER_REFUND) > -1;
        const ORDER_MANRETURN = payload.indexOf(MENU_ID.ORDER_MANRETURN) > -1;
        state.common.uacMenusMap = {
            AGENT_CREATE,
            AGENT_SEARCH,
            AGENT_BENEFIT_CHANGE,
            EMP_SEARCH,
            EMP_ADD,
            EMP_EDIT,
            EMP_RESET,
            POI_TYPE_SEARCH,
            POI_TYPE_ADD,
            POI_TYPE_EDIT,
            POI_TYPE_DISABLE,
            POI_SEARCH,
            POI_EXPORT,
            POI_DETAIL,
            ACCOUNT_SEARCH,
            ACCOUNT_EDIT,
            ACCOUNT_RESET_PASSWORD,
            ROLE_ADD,
            ROLE_EDIT,
            ROLE_PERMIT,
            ORDER_CLOSE,
            ORDER_SUSPEND,
            ORDER_REFUND,
            ORDER_MANRETURN,
        };
    },
    [types.SET_SIDE_VISIBLE](state: State, payload: boolean) {
        state.common.sideListVisible = payload;
    },
};

/* eslint-disable @typescript-eslint/ban-types */
export default new Store({
    state: {
        common: {
            user: {},
            uacMenusMap: {},
            sideListVisible: true,
        },
    },
    mutations,
    actions,
    modules,
});
