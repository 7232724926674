<template>
    <!-- 暂停计费 弹窗 -->
    <div class="drawer-action">
        <h3 class="title text-left">
            {{ $t('order.orderDetail.stopCharge') }}
        </h3>
        <mtd-form
            ref="stopChargeForm"
            :rules="rules"
            :model="stopChargeForm"
        >
            <mtd-row>
                <!-- <mtd-col :span="12">
                    <mtd-form-item
                        label="暂停原因:"
                        prop="reason"
                    >
                        <mtd-select v-model="stopChargeForm.reason">
                            <mtd-option
                                v-for="(item, index) in stopReasonOptions"
                                :key="index"
                                :label="item.name"
                                :value="item.value"
                            />
                        </mtd-select>
                    </mtd-form-item>
                </mtd-col> -->
            </mtd-row>
            <mtd-form-item
                :label="$t('order.orderDetail.usageDuration')"
                prop="returnTime"
            >
                <div class="consumptionTime">
                    <mtd-inputNumber
                        v-model="stopChargeForm.consumptionDays"
                        :controls="false"
                        :min="0"
                        :formatter="(value) => Number(value)"
                        style="width: 80px; margin-right: 8px"
                        @change="setConsumptionTime"
                    />
                    <span> {{ $t('order.orderDetail.day') }}</span>
                    <mtd-inputNumber
                        v-model="stopChargeForm.consumptionHours"
                        :controls="false"
                        :max="24"
                        :min="0"
                        :formatter="(value) => Number(value)"
                        style="width: 80px; margin: 0 8px"
                        @change="setConsumptionTime"
                    />
                    <span> {{ $t('order.orderDetail.hour') }}</span>
                    <mtd-inputNumber
                        v-model="stopChargeForm.consumptionMinutes"
                        :controls="false"
                        :max="59"
                        :min="0"
                        :formatter="(value) => Number(value)"
                        style="width: 80px; margin: 0 8px"
                        @change="setConsumptionTime"
                    />
                    <span> {{ $t('order.orderDetail.minute') }}</span>
                    <mtd-inputNumber
                        v-model="stopChargeForm.consumptionSeconds"
                        :controls="false"
                        :max="59"
                        :min="0"
                        :formatter="(value) => Number(value)"
                        style="width: 80px; margin: 0 8px"
                        @change="setConsumptionTime"
                    />
                    <span> {{ $t('order.orderDetail.second') }}</span>
                </div>
            </mtd-form-item>
            <mtd-form-item
                :label="$t('order.orderDetail.orderAmount')"
                style="color: red;font-weight: bold;"
            >
                {{ fee }}
            </mtd-form-item>
            <mtd-form-item
                :label="$t('order.orderDetail.suspendTime')"
                prop="suspendTime"
            >
                <mtd-select
                    v-model="stopChargeForm.suspendTime"
                >
                    <mtd-option
                        v-for="(item, index) in suspendTimeOptions"
                        :key="index"
                        :label="item.name"
                        :value="item.value"
                    />
                </mtd-select>
            </mtd-form-item>
            <mtd-form-item :label="$t('order.orderDetail.remark')">
                <mtd-textarea
                    v-model="stopChargeForm.remark"
                    rows="4"
                    maxlength="100"
                />
            </mtd-form-item>
        </mtd-form>
        <mtd-button
            type="primary"
            @click="stopCharge"
        >
            {{ $t('order.orderDetail.confirm') }}
        </mtd-button>
        <mtd-button @click="closeStopChargeModel">
            {{ $t('order.orderDetail.cancel') }}
        </mtd-button>
    </div>
</template>

<script>
import dayjs from '@/common/dayjs';
import STATUS from '@/constants/status';
import { SUSPEND_TIME } from '@/constants/orderConstants';
import OrderServiceApi from '@/api/order/orderService';
import utils from '@/common/util';

export default {
    name: 'StopCharge',
    props: {
        userInfo: {
            type: Object,
            default: () => ({}),
        },
        lendTime: {
            type: String,
            default: '',
        },
    },
    data() {
        this.suspendTimeOptions = SUSPEND_TIME;
        return {
            stopChargeForm: {
                consumptionDays: 0,
                consumptionHours: 0,
                consumptionMinutes: 0,
                consumptionSeconds: 0,
                returnTime: dayjs(this.lendTime).valueOf(),
                useTime: 0,
                suspendTime: 1440,
                reason: '',
                remark: '',
            },
            fee: 0,
            // stopReasonOptions: [
            //     {
            //         name: '机柜离线时归还',
            //         value: 'SC001',
            //     },
            //     {
            //         name: '未弹出充电宝',
            //         value: 'SC002',
            //     },
            //     {
            //         name: '机柜故障无法使用',
            //         value: 'SC003',
            //     },
            //     {
            //         name: '系统服务故障',
            //         value: 'SC004',
            //     },
            //     {
            //         name: '疫情等不可抗力因素',
            //         value: 'SC006',
            //     },
            //     {
            //         name: '其他',
            //         value: 'SC005',
            //     },
            // ],
            rules: {
                // reason: { required: true, message: '必填' },
                suspendTime: { required: true, message: this.$t('order.orderDetail.mustInput') },
            },
            source: {
                manager: 0, // 运营后台
                user: 1, // c端
            },
        };
    },
    watch: {
        'stopChargeForm.returnTime': function (val) {
            if (!val) return;
            this.getReturnFeeInfo();
        },
    },
    created() {
        this.getReturnTime(new Date());
    },
    methods: {
        stopCharge() {
            this.$refs.stopChargeForm.validate((valid) => {
                if (!valid) return;
                this.$mtd.confirm({
                    title: this.$t('order.orderDetail.stopCharge'),
                    message: this.$t('order.orderDetail.stopChargeConfirm'),
                    width: '430px',
                    type: 'warning',
                    showCancelButton: true,
                    onOk: async () => {
                        if (this.stopChargeForm.returnTime > Date.now()) {
                            this.$mtd.message({
                                message: this.$t('order.orderDetail.returnTimeRule'),
                                type: 'warning',
                            });
                            return;
                        }

                        const param = {
                            ...this.stopChargeForm,
                            ...this.userInfo,
                            source: this.source.manager,
                            orderId: this.$route.params.orderId,
                            userId: this.$route.params.userId,
                        };
                        try {
                            const { code } = await OrderServiceApi.stopCharge(param);
                            if (code === STATUS.SUCCESS) {
                                this.$mtd.message({
                                    message: 'success',
                                    type: 'success',
                                });
                                this.$emit('closeModel', {
                                    isShowModel: false,
                                    detailLoading: true,
                                });
                                setTimeout(() => {
                                    window.location.reload();// reload
                                }, 5000);
                            }
                        } catch (message) {
                            // this.$mtd.message({
                            //     message: 'error',
                            //     type: 'error',
                            // });
                        }
                    },
                });
            });
        },

        getReturnTime(value) {
            this.stopChargeForm.consumptionDays = dayjs(value).diff(this.lendTime, 'day');

            let operatedLendTime = dayjs(this.lendTime).add(this.stopChargeForm.consumptionDays, 'day');
            this.stopChargeForm.consumptionHours = dayjs(value).diff(operatedLendTime, 'hour');

            operatedLendTime = dayjs(operatedLendTime).add(this.stopChargeForm.consumptionHours, 'hour');
            this.stopChargeForm.consumptionMinutes = dayjs(value).diff(operatedLendTime, 'minute');

            operatedLendTime = dayjs(operatedLendTime).add(this.stopChargeForm.consumptionMinutes, 'minute');
            this.stopChargeForm.consumptionSeconds = dayjs(value).diff(operatedLendTime, 'second');

            this.stopChargeForm.returnTime = dayjs(value).valueOf();
            const {
                consumptionDays, consumptionHours, consumptionMinutes, consumptionSeconds,
            } = this.stopChargeForm || {};
            this.stopChargeForm.useTime = (((consumptionDays * 24 + consumptionHours) * 60 + consumptionMinutes) * 60 + consumptionSeconds) * 1000;
        },
        async getReturnFeeInfo() {
            try {
                const params = {
                    userId: this.$route.params.userId,
                    orderId: this.$route.params.orderId,
                    bizReturnTime: this.stopChargeForm.returnTime,
                    optType: 1, // 后端临时要求
                };
                const res = await OrderServiceApi.getReturnFee(params);
                const { data, code, msg: message } = res;
                if (code === STATUS.SUCCESS) {
                    this.fee = utils.formatPrice(data.fee); // 人工还宝实收金额
                } else {
                    this.$mtd.message({
                        message,
                        type: 'error',
                    });
                }
            } catch (e) {
                // this.$mtd.message({
                //     message: 'error',
                //     type: 'warning',
                // });
            }
        },
        setConsumptionTime() {
            const {
                consumptionDays, consumptionHours, consumptionMinutes, consumptionSeconds,
            } = this.stopChargeForm || {};

            const returnTime = dayjs(this.lendTime)
                .add(consumptionDays * 24, 'hour')
                .add(consumptionHours, 'hour')
                .add(consumptionMinutes, 'minute')
                .add(consumptionSeconds, 'second');

            this.stopChargeForm.useTime = (((consumptionDays * 24 + consumptionHours) * 60 + consumptionMinutes) * 60 + consumptionSeconds) * 1000;
            this.stopChargeForm.returnTime = returnTime.valueOf();
            console.log(returnTime.format('YYYY-MM-DD HH:mm:ss'));
        },
        closeStopChargeModel() {
            this.$emit('closeModel', {
                isShowModel: false,
                detailLoading: false,
            });
        },
    },
};
</script>

<style lang="less" scoped>
.mtd-drawer-close {
  left: 16px !important;
  right: auto;
}

.text-left {
  text-align: left;
}

.drawer-action {
  text-align: right;

  &>button {
    min-width: 80px;
  }

  button+button {
    margin-left: 12px;
  }
}

.mtd-form {
  margin-top: 20px;
}
</style>
