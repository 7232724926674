export default {
    common: {
        menu: {
            agent: '代理商',
            agentList: '代理商管理',
            EmployeeManagement: '员工管理',
            store: '门店',
            storeList: '门店列表',
            storeType: '门店类型',
            collapseText: '收起',
            storeAccount: '商户账号',
            permit: '权限',
            permitList: '权限列表',
            device: '设备',
            asset: '设备列表',
            bill: '结算',
            benefit: '订单分润',
            cashout: '提现记录',
            orderManage: '订单管理',
            orderList: '订单列表',
        },
        login: {
            name: '充电宝后台管理系统',
            title: '登陆',
            placehloder: {
                username: '请输入账号/电话号码',
                password: '请输入密码',
            },
            errorTip: '请输入你的账号/电话号码以及密码',
            logout: '退出',
        },
        selectPlaceholder: '请选择',
        emptyText: '暂无数据',
        pleaseFillInCompletely: '请填写完整',
    },
    agentCreate: {
        agentBasicInfo: '代理商基础信息',
        agentName: '代理商名称',
        principalName: '负责人名称',
        phoneNumber: '电话号码',
        email: '邮箱',
        detailedAddress: '详细地址',
        agentInfo: '代理商信息',
        contractFile: '合同文件',
        clickUpload: '点击上传',
        chargeInfo: '收费信息',
        headCompanyProfit: '总公司分润',
        bankAccountInfo: '银行账户信息',
        bankName: '银行名称',
        branchName: '支行名称',
        bankType: '银行类型',
        ordinary: '普通',
        savings: '储蓄',
        bankAccount: '银行账户',
        accountName: '账户名称',
        cancel: '取消',
        submit: '提交',
        pleaseEnterAgentName: '请输入代理商名称',
        pleaseEnterPrincipalName: '请输入负责人名称',
        pleaseEnterCorrectPhoneNumber: '请输入正确的电话号码',
        pleaseEnterCorrectEmailFormat: '请输入正确的邮箱格式',
        pleaseEnterDetailedAddress: '请输入详细地址',
        pleaseEnterContractFile: '请输入合同文件',
        pleaseEnterBankName: '请输入银行名称',
        pleaseEnterBranchName: '请输入支行名称',
        pleaseEnterBankAccount: '请输入银行账户',
        pleaseEnterAccountName: '请输入账户名称',
        pleaseFillInCompletely: '请填写完整',
        pleaseSelectBankType: '请选择银行类型',
        addSuccess: '添加成功',
        parentName: '上级代理商',
    },
    agentList: {
        agentId: '代理商ID',
        agentName: '代理商名称',
        agentLevel: '代理商级别',
        headCompany: '总公司',
        agent: '代理商',
        agentAccount: '代理商账号',
        principalName: '负责人姓名',
        type: '代理商类型',
        query: '查询',
        create: '创建',
        operation: '操作',
        modifyProfitInfo: '修改分润信息',
        headCompanyProfit: '总公司分润',
        cancel: '取消',
        confirm: '确定',
        superiorAgentName: '上级代理商名称',
        principalPhoneNumber: '负责人手机号',
        email: '邮箱',
        belongingPlace: '所属地',
        headCompanyProfitRatio: '总公司分润比例',
        bankName: '银行名称',
        branchName: '支行名称',
        accountType: '账户类型',
        bankAccount: '银行账号',
        accountName: '账户名称',
        withdrawableAmount: '可提现金额',
        cooperationStatus: '合作状态',
        updateTime: '更新时间',
        createTime: '创建时间',
        pleaseEnterNumberWithTwoDecimalPlaces: '请输入的数字最多带两位小数',
        modificationSuccess: '修改成功',
        changeTip: '请输入0-100的整数',
    },
    agentUserItem: {
        agentName: '代理商名称',
        userName: '用户名',
        phoneNumber: '电话号码',
        email: '邮箱',
        loginPassword: '登陆密码',
        role: '角色',
        status: '状态',
        enable: '启用',
        disable: '禁用',
        cancel: '取消',
        confirm: '确定',
        pleaseEnteragentName: '请选择代理商',
        pleaseEnterUserName: '请输入用户名',
        pleaseEnterPhoneNumber: '请输入电话号码',
        pleaseEnterEmail: '请输入邮箱',
        pleaseEnterLoginPassword: '请输入至少6位数字和字母组合',
        pleaseSelectRole: '请选择角色',
        pleaseEnterStatus: '请输入状态',
        edit: '编辑',
        saveSuccess: '保存成功',
        submitErrorTip: '请填写完整',
    },
    agentUser: {
        belongingCooperator: '所属代理商',
        loginAccount: '登陆账号',
        userName: '用户名',
        phoneNumber: '手机号',
        role: '角色',
        search: '搜索',
        reset: '重置',
        add: '添加',
        email: '邮箱',
        isCooperatorResponsiblePerson: '是否代理商负责人',
        is: '是否',
        status: '状态',
        enableDisable: '启用禁用',
        operation: '操作',
        edit: '编辑',
        resetPassword: '重置密码',
        newPassword: '新密码',
        cancel: '取消',
        confirm: '确定',
        addUser: '新增用户',
        editUserInfo: '编辑用户信息',
        resetSuccess: '重置成功',
        enable: '启用',
        disable: '禁用',
        yes: '是',
        no: '否',
    },
    deviceAssetManagement: {
        device: '设备SN',
        belongingSubject: '归属主体',
        deviceType: '设备类型',
        businessStatus: '业务状态',
        belongingPersonnel: '资产归属人员',
        search: '搜索',
        reset: '重置',
        export: '导出',
        batchOperation: '批量操作',
        cabinetChargingTreasure: '机柜充电宝',
        deviceStatus: '设备状态',
        locatedStore: '所在门店',
        storeBelonging: '门店归属主体',
        locatedCabinet: '所在机柜',
        deviceInformation: '设备信息',
        view: '查看',
        circulationRecord: '流转记录',
        chargingTreasure: '充电宝SN',
        positionNumber: '仓位号',
        electricity: '电量',
        chargingStatus: '充电状态',
        faultStatus: '故障状态',
        return: '返回',
        refresh: '刷新',
        cabinet: '机柜',
        networkStatus: '网络状态',
        lastOnlineTime: '最后在线时间',
        inTreasureNumber: '在仓宝数',
        positionStatus: '仓位状态',
        operation: '操作',
        popUp: '弹出',
        popUpAll: '全部弹出',
        uploadFile: '上传文件',
        clickUpload: '点击上传',
        downloadTemplate: '下载模版',
        targetSubject: '目标主体',
        operationPrompt: '操作提示：仅未入库的设备支持入库，仅库存设备支持退回库存，在途进店完成设备支持转移。',
        cancel: '取消',
        returnToWarehouse: '入库退回',
        deviceTransfer: '设备转移',
        batchWarehousing: '批量入库',
        operator: '操作人',
        operationType: '操作类型',
        operationTime: '操作时间',
        status: '状态',
        popUpSuccess: '弹出成功',
        batchErrorTip: '请上传SN并输入目标主体',
        batchReturnErrorTip: '请上传SN',
        warehouse: '仓库',
        enRoute: '在途',
        enterShopFinish: '进店完成',
        lending: '租借中',
        userShop: '售卖',
        userLoss: '用户丢失',
        repairing: '维修中',
        loss: '设备丢失',
        scrap: '报废',
        notExist: '不存在',
        support: '支架',
        unkown: '未知',
        offline: '离线',
        online: '在线',
        inCabin: '在仓',
        offCabin: '不在仓',
        poiBelongEmp: '门店归属BD',
        addrAble: '仓位可用',
        addrDisable: '仓位封仓',
        battery: '充电宝',
        popOption: '弹宝选项',
        popUnlock: '弹出可充电',
        popLock: '弹出不可充电',
        batchOperationSuccess: '操作成功',
        actionTitle: '操作提示',
        actionBtn: '确定',
    },
    permitIndex: {
        create: '创建',
        operation: '操作',
        edit: '编辑',
        authority: '权限',
        addRoleInfo: '新增角色信息',
        modifyRoleInfo: '修改角色信息',
        roleName: '角色名称',
        roleIdentification: '角色标识',
        roleDescription: '角色描述',
        cancel: '取消',
        confirm: '确定',
        assignAuthority: '分配权限',
        update: '更新',
        serialNumber: '序号',
        pleaseEnterRoleName: '请输入角色名称',
        pleaseEnterRoleIdentification: '请输入角色标识',
        pleaseFillInCompletely: '请填写完整',
        saveSuccess: '保存成功',
        updateSuccess: '更新成功',
    },
    poiAccount: {
        merchantAccount: '商户账号',
        email: '邮箱',
        merchantName: '商户名称',
        query: '查询',
        savingsOrdinary: '储蓄普通',
        operation: '操作',
        edit: '编辑',
        resendPassword: '重发密码',
        merchantInfoModification: '商户信息修改',
        account: '账号',
        bankName: '银行名称',
        branchName: '支行名称',
        accountType: '账户类型',
        ordinary: '普通',
        savings: '储蓄',
        bankAccount: '银行账号',
        accountName: '账户名称',
        cancel: '取消',
        confirm: '确定',
        cooperationStoreNumber: '合作门店数',
        withdrawableAmount: '可提现金额',
        updateSuccess: '更新成功',
        resendPasswordSuccess: '重发密码成功',
        pleaseEnterAccount: '请输入账号',
        pleaseEnterCorrectEmailFormat: '请输入正确的邮箱账号',
        pleaseEnterName: '请输入商户名称',
        pleaseEnterBankName: '请输入银行名称',
        pleaseEnterBranchName: '请输入支行名称',
        pleaseEnterBankType: '请输入账户类型',
        pleaseEnterBankAccount: '请输入账户名称',
        pleaseEnterBankNumber: '请输入银行账号',
    },
    poiDetail: {
        basicInfo: '基本信息',
        edit: '编辑',
        cooperationInfo: '合作信息',
        remark: '备注',
        inputTip: '输入内容不超过个50字符',
        return: '返回',
        saveChanges: '保存更改',
        notYetOpen: '尚未营业',
        suspendBusiness: '暂停营业',
        inBusiness: '营业中',
        closed: '已关闭',
        unrecognizable: '无法识别',
        storeName: '门店名称',
        businessStatus: '营业状态',
        personInChargePhone: '负责人电话',
        storeId: '门店ID',
        businessHours: '营业时间',
        personInChargeEmail: '负责人邮箱',
        storeType: '门店类型',
        storeAddress: '门店地址',
        cooperationStatus: '合作状态',
        merchantAccount: '商户账号',
        belongingAgent: '归属代理商',
        merchantEmail: '商户邮箱',
        belongingBd: '归属BD',
        prompt: '提示',
        saveTip: '存在编辑中的信息，是否确认返回？',
        confirmReturn: '确认返回',
        saveSuccess: '保存成功',
        required: '必填',
        unvaild: '不符合规范',
    },
    poiList: {
        storeId: '门店ID',
        belongingAgent: '归属代理商',
        query: '查询',
        operation: '操作',
        basicInfo: '基本信息',
        storeName: '门店名称',
        storeType: '门店类型',
        businessStatus: '营业状态',
        businessHours: '营业时间',
        storeAddress: '门店地址',
        personInChargePhoneNumber: '负责人手机号',
        cooperationStatus: '合作状态',
        belongingId: '归属BD',
        cabinetNumber: '机柜数',
        currentTreasureNumber: '当前宝数',
        updateTime: '更新时间',
        belongingBD: '归属BD',
        account: '商户账号',
        export: '导出',
        exportSuccessTip: '导出成功，请邮件查收',
        benefit: '分润比例',
    },
    poiType: {
        typeName: '类型名称',
        typeStatus: '类型状态',
        disable: '禁用',
        enable: '正常',
        query: '查询',
        add: '添加',
        name: '名称',
        remark: '备注',
        status: '状态',
        disableEnable: '禁用启用',
        createTime: '创建时间',
        operation: '操作',
        modify: '修改',
        storeTypeName: '门店类型名称',
        cancel: '取消',
        confirm: '确定',
        submitSuccess: '提交成功',
        addText: '新增',
        editText: '编辑',
        pleaseEnterTypeName: '请输入门店类型名称',
        pleaseEnterTypeStatus: '清选择状态',
    },
    benefitIndex: {
        paymentRefundDate: '支付退款日期',
        selectDateRange: '选择日期范围',
        orderNumber: '订单编号',
        pleaseEnterOrderNumber: '请输入订单编号',
        store: '门店',
        search: '搜索',
        export: '导出',
        billMonth: '账单月份',
        billDate: '账单日期',
        orderType: '订单类型',
        isCappedBilling: '是否封顶计费',
        orderAmount: '订单金额',
        storeName: '门店名称',
        defaultAmount: '违约金额',
        defaultTaxAmount: '违约税金',
        rentTaxAmount: '租借税金',
        rentAmount: '租借金额',
        cooperationMode: '合作模式',
        merchantAccount: '商家账号',
        agent: '代理商',
        agentName: '代理商名称',
        profitSharingRatio: '分润比例',
        storeProfitSharingRatio: '门店分润比例',
        headquartersProfitSharingRatio: '总公司分润比例',
        defaultFeeProfitSharingAmount: '违约费用MT分润金额',
        defaultFeeHeadquartersProfitSharingAmount: '违约费用总公司分润金额',
        rentFeeProfitSharingAmount: '租借费用MT分润金额',
        rentFeeHeadquartersProfitSharingAmount: '租借费用总公司分润金额',
        rentFeeAgentParticipationSharingAmount: '租借费用代理参与分成金额',
        rentFeeStoreProfitSharingAmount: '租借费用门店分润金额',
        rentFeeAgentProfitSharingAmount: '租借费用代理分润金额',
        headquartersProfitSharingAmount: '总公司总分润金额',
        headquartersAmount: '总分润金额',
        cutDay: '支付/退款日期',
    },
    cashoutList: {
        initiationDate: '发起日期',
        selectDateRange: '选择日期范围',
        merchantAccountAgent: '商家账号代理商',
        pleaseEnterMerchantAccountAgent: '请输入商家账号ID/代理商Id',
        payeeAccountName: '收款账户名称',
        pleaseEnterPayeeAccountName: '请输入收款账户名称',
        merchantAccountId: '商家账号ID/代理商ID',
        merchantAccountName: '商家账号名称/代理商名称',
        customerType: '客户类型',
        merchant: '商家',
        meituan: '美团',
        agent: '代理商',
        withdrawalInitiationTime: '提现发起时间',
        merchantAccountNameAgentName: '商家账号名称代理商名称',
        taxAmount: '税金',
        billAmount: '账单金额',
        withdrawalAmount: '提现金额',
        bankName: '银行名',
        branchName: '支行名',
        accountType: '账户类型',
        accountName: '银行账户名',
        bankCardNumber: '银行卡号',
        search: '搜索',
        export: '导出',
        required: '必填',
    },
    order: {
        orderList: {
            searchForm: {
                pleaseSelectPh: '请选择',
                pleaseInputPh: '请输入',
                orderNumber: '订单编号',
                orderNumberPh: '请输入订单号',
                phoneNumber: '手机号',
                orderStatus: '状态',
                depositType: '押金类型',
                userId: '用户ID',
                lendPoi: '租借POI',
                returnPoi: '归还POI',
                batterySn: '宝SN',
                lendCabinSn: '租借机柜',
                returnCabinSn: '归还机柜',
                lendPoiName: '租借门店',
                returnPoiName: '归还门店',
                createOrderTime: '下单时间',
                returnTime: '归还时间',
                search: '查询',
                resetting: '重置',
                expand: '展开',
                retract: '收起',
                storeName: '门店名称',
                merchantAccount: '商户账号',
                createTime: '创建时间',
                updateTime: '更新时间',
                reset: '重置',
            },
            tableData: {
                orderId: '订单编号',
                userId: '用户ID',
                createTime: '下单时间',
                returnTime: '归还时间',
                status: '订单状态',
                payMode: '押金类型',
                actualFee: '实付金额(日元)',
                lendPoiName: '租借地',
                returnPoiName: '归还地',
                hand: '操作',
                check: '查看',
            },
        },
        orderDetail: {
            artificialReturn: '人工还宝',
            lendTime: '弹出时间',
            usageDuration: '使用时长',
            day: '天',
            hour: '小时',
            minute: '分钟',
            second: '秒',
            returnTime: '归还时间',
            chooseTime: '选择时间',
            returnCabin: '归还机柜',
            returnCabinPd: '请输入机柜SN',
            returnPoiName: '归还门店',
            remark: '备注',
            actualAmountReceived: '实收金额',
            return: '归还',
            confirm: '确认',
            cancel: '取消',
            mustInput: '必填',
            returnTimeRule: '归还时间必须小于当前时间',
            artificialReturnConfirm: '人工归还确认',
            artificialReturnConfirmDes: '确认对订单进行人工归还么',

            closeOrder: '关单',
            closeOrderDesc: '关闭订单',
            closeOrderConfirm: '确定关闭订单吗？',

            stopCharge: '暂停计费',
            orderAmount: '订单金额',
            suspendTime: '暂停时长',
            stopTime24: '24小时',
            stopChargeConfirm: '确定暂停计费吗？',

            refund: '退款',
            originalFee: '原支付金额',
            canRefundFee: '可退金额',
            refundFee: '退款金额',
            computedRefundFee: '退款金额要大于0元',
            refundConfirm: '退款确认',
            refundConfirmMsg1: '确认对订单进行{0}的退款么',
            refundConfirmMsg2: '订单已退款{0}，是否继续对订单进行{1}的退款',

            orderId: '订单编号',
            userName: '用户名',
            mobile: '手机号',
            createTime: '创建时间',
            payTime: '支付日期',
            payId: '支付单号',
            status: '状态',
            actualFee: '实付金额',

            lendInfo: '租借信息',
            lendPoiName: '租借地',
            lendPoiId: '租借POI',
            lendCabinType: '租借机柜SN',
            orderLendTime: '指令弹出时间',
            returnPoiId: '归还POI',
            returnCabinType: '归还机柜SN',
            bizReturnTime: '业务归还时间',
            lendBatteryType: '宝SN',
            returnType: '归还方式',
            returnTypeCase1: '正常',
            returnTypeCase2: '断电',
            returnTypeCase3: '未归还',
            returnTypeCase4: '00锁止',
            returnTypeCase5: '00锁止后丢失',
            returnTypeCase6: '断电00锁止',
            returnTypeCase7: '断电00锁止后丢失',
            returnTypeCase8: '异物归还',
            manualReturnCase1: '人工',
            orderReturnTime: '指令归还时间',
            userPhonePower: '手机初始电量',
            lendPower: '宝电量',
            chargeType: '计费方式',
            chargeTypeCase1: '指令',
            chargeTypeCase2: '人工',
            chargeTypeCase3: '断电',
            chargeTypeCase4: '耗电',
            chargeTypeCase5: '故障',
            chargeTypeCase6: '申诉',
            chargeTypeCase7: '00锁止归还失败',
            chargeTypeCase8: '断电00锁止归还失败',

            userOptLog: '用户操作日志',
            optType: '操作类型',
            operateUserName: '操作人',
            operateResult: '执行结果',
            optTime: '操作时间',
            manualLog: '人工操作日志',
            operateContent: '操作内容',
            optTypeCase1: '创建{0}支付单',

            orderFlow: '流程进度',
            orderFlowStep1: '创建订单',
            orderFlowStep2: '弹出成功',
            orderFlowStep3: '归还成功',
            orderFlowStep4: '完成',
            leaseDuration: '租借时长',

            payInfo: '支付信息',
            orderType: '订单类型',
            depositFee: '押金金额',
            depositRefundFee: '系统退款',
            payTpye: '支付方式',
            originFee: '订单原价',
            needPayFee: '应付金额',
            manualRefundFee: '人工退款',
            chargingPeriod: '计费时长',
            chargingRule: '计费规则',
        },
        orderConstants: {
            MAX_LENGTH_VALIDATE: '最多输入100个字符',
            DEPOSIT_TYPE_DESC: {
                DEPOSIT: '押金',
                NO_REFUND: '免押',
            },
            ORDER_STATUS_QUERY_1: '待授权',
            ORDER_STATUS_QUERY_2: '待付款',
            ORDER_STATUS_QUERY_3: '租借中',
            ORDER_STATUS_QUERY_4: '归还成功',
            ORDER_STATUS_QUERY_5: '已完成',
            ORDER_STATUS_QUERY_6: '人工退款',
            ORDER_STATUS_QUERY_7: '弹出中',
            ORDER_STATUS_QUERY_8: '已关闭',
            SOURCE_MAP: {
                Line: 'LINE小程序',
                App: 'App',
            },
            OPT_TYPE_MAP: {
                1000: '创建业务订单',
                1001: '删除订单',
                1101: '创建支付单',
                1102: '发起免密扣款',
                1103: '押金系统退款',
                1104: '免密扣款成功',
                1105: '主动支付',
                1201: '弹出充电宝指令',
                1202: '弹出充电宝',
                1203: '归还充电宝',
                1301: '人工归还',
                1302: '人工退款',
                1303: '人工关单',
                1304: '暂停计费',
                1305: '延长暂停时长',
                1310: 'BD退款',
                1311: '未支付订单改价',
                1312: '主动服务改价',
                1313: '删除暂停计费',
                1204: '创建订单申诉',
                1205: '订单申诉成功',
                1206: '订单申诉失败',
                1207: '封顶后退款',
                1208: '商家申请退款',
                1401: '主动买宝',
                1402: '售后发券',
                1403: '售后废券',
                2100: '智能客服完结订单',
                2101: '业务员审核',
            },
            RESULT_MAP: {
                0: '成功',
                1: '失败',
            },
            DATEPICKER_SHORTCUTS: {
                today: '今天',
                yesterday: '昨天',
                last7Days: '过去7天',
                last14Days: '过去14天',
                last30Days: '过去30天',
                last90Days: '过去90天',
            },
            SUSPEND_TIME: {
                '24Hours': '24小时',
            },
        },
    },
    el: {
        locale: 'zh-CN',
        datepicker: {
            selectDate: '选择日期',
            selectTime: '选择时间',
            startTime: '开始时间',
            endTime: '结束时间',
            now: '此刻',
            ok: '确定',
            month: '月',
            month1: '1 月',
            month2: '2 月',
            month3: '3 月',
            month4: '4 月',
            month5: '5 月',
            month6: '6 月',
            month7: '7 月',
            month8: '8 月',
            month9: '9 月',
            month10: '10 月',
            month11: '11 月',
            month12: '12 月',
            year: '年',
            weeks: {
                sun: '日',
                mon: '一',
                tue: '二',
                wed: '三',
                thu: '四',
                fri: '五',
                sat: '六',
            },
            months: {
                m1: '1月',
                m2: '2月',
                m3: '3月',
                m4: '4月',
                m5: '5月',
                m6: '6月',
                m7: '7月',
                m8: '8月',
                m9: '9月',
                m10: '10月',
                m11: '11月',
                m12: '12月',
            },
        },
        pagination: {
            goto: '前往',
            pagesize: '条/页',
            total: '共 {total} 条',
            pageClassifier: '页',
        },
    },
};
