

































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import agentSelect from '@/views/common/agentSelect.vue';
import dayjs from '@/common/dayjs';
// eslint-disable-next-line import/extensions
import config from '@/common/config';

@Component({ components: { agentSelect } })
export default class agentList extends Vue {
    @State((state) => state.common.user) user: any;

    form: any = {
        date: [dayjs().subtract(1, 'month'), dayjs()],
        tradeId: '',
        poiId: '',
    }

    tableData = [];

    total: number = 0;

    pagination = {
        pageNum: 1,
        pageSize: 10,
    }

    startDate = dayjs().subtract(1, 'month').toDate();

    dateOptions = {
        disabledDate(date: any) {
            return date && date.getTime() > Date.now();
        },
    };

    async created() {
        this.getTableData();
    }

    handlePicker(data: any) {
        const { from, selecting } = data;
        if (!selecting) {
            this.dateOptions = {
                disabledDate(date: any) {
                    return date && date.getTime() > Date.now();
                },
            };
        } else if (from) {
            this.dateOptions = {
                disabledDate(date: any) {
                    return (date && date.getTime() > Date.now()) || (date && date > dayjs(from).add(1, 'month').toDate()) || (date && date < dayjs(from).subtract(1, 'month').toDate());
                },
            };
        }
    }

    async getTableData() {
        this.hadleFormData();
        const { data } = await this.$request.post(`${config.billBaseURL}/settle-api/clear/pageList`, {
            ...this.form,
            date: null,
            ...this.pagination,
        });
        if (data && data.content) {
            this.tableData = data.content;
            this.total = data.totalCount;
        } else {
            this.tableData = [];
            this.total = 0;
        }
    }

    hadleFormData() {
        Object.keys(this.form).forEach((key) => {
            if (this.form[key] === '') {
                this.form[key] = null;
            }
        });
        if (this.form.date && this.form.date.length === 2) {
            this.form.startCutDay = dayjs(this.form.date[0]).format('YYYYMMDD');
            this.form.endCutDay = dayjs(this.form.date[1]).format('YYYYMMDD');
        }
    }

    async exportData() {
        this.hadleFormData();
        const { code, data } = await this.$request.post(`${config.billBaseURL}/settle-api/clear/export`, {
            ...this.form,
            date: null,
        });
        if (code === 0 && data) {
            window.open(data);
        }
    }

    search() {
        this.pagination.pageNum = 1;
        this.getTableData();
    }

    change() {
        this.getTableData();
    }
}
