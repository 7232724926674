import i18n from '@/common/i18n';

const MAX_LENGTH_VALIDATE = {
    max: 100,
    message: i18n.t('order.orderConstants.MAX_LENGTH_VALIDATE'),
};
const DEPOSIT_TYPE = {
    DEPOSIT: 1,
    NO_REFUND: 2,
};
const DEPOSIT_TYPE_DESC = {
    DEPOSIT: i18n.t('order.orderConstants.DEPOSIT_TYPE_DESC.DEPOSIT'),
    NO_REFUND: i18n.t('order.orderConstants.DEPOSIT_TYPE_DESC.NO_REFUND'),
};
const DEPOSIT_TYPE_LIST = [
    {
        value: DEPOSIT_TYPE.DEPOSIT,
        label: DEPOSIT_TYPE_DESC.DEPOSIT,
    },
    {
        value: DEPOSIT_TYPE.NO_REFUND,
        label: DEPOSIT_TYPE_DESC.NO_REFUND,
    },
];
/* 订单查询状态列表 */
const ORDER_STATUS_QUERY = [
    {
        label: i18n.t('order.orderConstants.ORDER_STATUS_QUERY_1'), //待授权
        value: '100',
    },
    {
        label: i18n.t('order.orderConstants.ORDER_STATUS_QUERY_2'), // 待付款
        value: '401, 402, 403',
    },
    // {
    //     label: '已支付',  // 经过产品评审，'已支付'状态无效，暂时去掉
    //     value: '201, 202',
    // },
    {
        label: i18n.t('order.orderConstants.ORDER_STATUS_QUERY_3'), //租借中
        value: '200',
    },
    {
        label: i18n.t('order.orderConstants.ORDER_STATUS_QUERY_4'), // 归还成功
        value: '300, 301, 302',
    },
    {
        label: i18n.t('order.orderConstants.ORDER_STATUS_QUERY_5'), //已完成
        value: '1000, 1001, 1002, 1003',
    },
    {
        label: i18n.t('order.orderConstants.ORDER_STATUS_QUERY_6'), //人工退款
        value: '1100',
    },
    {
        label: i18n.t('order.orderConstants.ORDER_STATUS_QUERY_7'), //弹出中
        value: '190',
    },
    {
        label: i18n.t('order.orderConstants.ORDER_STATUS_QUERY_8'), //已关闭
        value: '900, 901, 902',
    },
];

const ORDER_FORM_FILTER = {
    orderId: null,
    mobile: null,
    status: null,
    creditCompleteStatus: null,
    payMode: null,
    username: null,
    userId: null,
    source: null,
    lendPoiId: null,
    lendPoiName: null,
    returnPoiId: null,
    returnPoiName: null,
    lendCabinSn: null,
    returnCabinSn: null,
    createTimeRange: [], // 下单开始结束时间
    payTimeRange: [], // 支付开始结束时间
    popUpTimeRange: [], // 弹宝开始结束时间
    returnTimeRange: [], // 归还开始结束时间
    batterySn: null,
};
// 订单来源:0:LINE;1:安卓APP-JP;2:苹果APP-JP
const SOURCE = {
    Line: 0,
    App: 1,
    WeChat: 2,
};
const SOURCE_MAP = {
    [SOURCE.Line]: i18n.t('order.orderConstants.SOURCE_MAP.Line'),
    [SOURCE.App]: i18n.t('order.orderConstants.SOURCE_MAP.App'),
    [SOURCE.WeChat]: 'WeChat',
};
// export const SOURCE_LIST = [{
//     value: SOURCE.Line,
//     label: 'LINE小程序',
// }, {
//     value: SOURCE.App,
//     label: 'App',
// }];

const COUPON_SOURCE = {
    KA: 5,
};

// const RIGHTS_TYPE = {
//     DEFAULT: 0, //  默认兜底
//     MERCHANT: 1, // 商家权益
//     TIME_CARD: 2, // 次卡
//     COUPON: 3, // 优惠券
// };

// const RIGHTS_INFO = {
//     [RIGHTS_TYPE.DEFAULT]: {
//         name: '默认',
//     },
//     [RIGHTS_TYPE.MERCHANT]: {
//         name: '商家权益',
//     },
//     [RIGHTS_TYPE.TIME_CARD]: {
//         name: '次卡',
//     },
//     [RIGHTS_TYPE.COUPON]: {
//         name: '优惠券',
//     },
// };

const OPT_TYPE_MAP = {
    1000: i18n.t('order.orderConstants.OPT_TYPE_MAP.1000'),
    1001: i18n.t('order.orderConstants.OPT_TYPE_MAP.1001'),
    1101: i18n.t('order.orderConstants.OPT_TYPE_MAP.1101'),
    1102: i18n.t('order.orderConstants.OPT_TYPE_MAP.1102'),
    1103: i18n.t('order.orderConstants.OPT_TYPE_MAP.1103'),
    1104: i18n.t('order.orderConstants.OPT_TYPE_MAP.1104'),
    1105: i18n.t('order.orderConstants.OPT_TYPE_MAP.1105'),
    1201: i18n.t('order.orderConstants.OPT_TYPE_MAP.1201'),
    1202: i18n.t('order.orderConstants.OPT_TYPE_MAP.1202'),
    1203: i18n.t('order.orderConstants.OPT_TYPE_MAP.1203'),
    1301: i18n.t('order.orderConstants.OPT_TYPE_MAP.1301'),
    1302: i18n.t('order.orderConstants.OPT_TYPE_MAP.1302'),
    1303: i18n.t('order.orderConstants.OPT_TYPE_MAP.1303'),
    1304: i18n.t('order.orderConstants.OPT_TYPE_MAP.1304'),
    1305: i18n.t('order.orderConstants.OPT_TYPE_MAP.1305'),
    1310: i18n.t('order.orderConstants.OPT_TYPE_MAP.1310'),
    1311: i18n.t('order.orderConstants.OPT_TYPE_MAP.1311'),
    1312: i18n.t('order.orderConstants.OPT_TYPE_MAP.1312'),
    1313: i18n.t('order.orderConstants.OPT_TYPE_MAP.1313'),
    1204: i18n.t('order.orderConstants.OPT_TYPE_MAP.1204'),
    1205: i18n.t('order.orderConstants.OPT_TYPE_MAP.1205'),
    1206: i18n.t('order.orderConstants.OPT_TYPE_MAP.1206'),
    1207: i18n.t('order.orderConstants.OPT_TYPE_MAP.1207'),
    1208: i18n.t('order.orderConstants.OPT_TYPE_MAP.1208'),
    1401: i18n.t('order.orderConstants.OPT_TYPE_MAP.1401'),
    1402: i18n.t('order.orderConstants.OPT_TYPE_MAP.1402'),
    1403: i18n.t('order.orderConstants.OPT_TYPE_MAP.1403'),
    2100: i18n.t('order.orderConstants.OPT_TYPE_MAP.2100'),
    2101: i18n.t('order.orderConstants.OPT_TYPE_MAP.2101'),
};
const OPT_TYPE_CREATE_PAY_ORDER = 1101;
const HEADER_STATUS_STEP_ONE = 900;
const HEADER_STATUS_STEP_TWO = [901, 902];
const RESULT_MAP = {
    0: i18n.t('order.orderConstants.RESULT_MAP.0'),
    1: i18n.t('order.orderConstants.RESULT_MAP.1'),
};
// const BATTERY_TYPE_MAP = {
//     1: 'TZ1 充电宝',
//     2: 'TZ2 充电宝',
//     3: 'TZ3 充电宝',
//     4: 'TZ4 充电宝',
//     5: 'TZ5 充电宝',
// };
const DISABLED_REFUNDE_LIST = [1000, 1002, 1003, 1001, 1100];
const DISABLED_RETURN_LIST = [200];
const DISABLED_CLOSE_ORDER_LIST = [401, 402, 403, 190];
// const DISABLED_CHANGE_PRICE = [401, 402, 403];
const DISABLED_COUPON_LIST = [0, 3, 4];
// const ORDER_OPERATE_RIGHT = 'op-7375955D5FD231F43A64C1B16D3F4E62';
const DATEPICKER_SHORTCUTS = [{
    text: i18n.t('order.orderConstants.DATEPICKER_SHORTCUTS.today'),
    onClick(picker: any) {
        const start = new Date();
        const end = new Date();
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        end.setHours(23);
        end.setMinutes(23);
        end.setSeconds(59);
        picker.$emit('pick', [start, end]);
    },
}, {
    text: i18n.t('order.orderConstants.DATEPICKER_SHORTCUTS.yesterday'),
    onClick(picker: any) {
        const start = new Date();
        const end = new Date();
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        start.setTime(start.getTime() - 3600 * 1000 * 24);
        end.setHours(23);
        end.setMinutes(23);
        end.setSeconds(59);
        end.setTime(end.getTime() - 3600 * 1000 * 24);
        picker.$emit('pick', [start, end]);
    },
}, {
    text: i18n.t('order.orderConstants.DATEPICKER_SHORTCUTS.last7Days'),
    onClick(picker: any) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit('pick', [start, end]);
    },
}, {
    text: i18n.t('order.orderConstants.DATEPICKER_SHORTCUTS.last14Days'),
    onClick(picker: any) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 14);
        picker.$emit('pick', [start, end]);
    },
}, {
    text: i18n.t('order.orderConstants.DATEPICKER_SHORTCUTS.last30Days'),
    onClick(picker: any) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit('pick', [start, end]);
    },
}, {
    text: i18n.t('order.orderConstants.DATEPICKER_SHORTCUTS.last90Days'),
    onClick(picker: any) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit('pick', [start, end]);
    },
}];

// const FEED_BACK_TYPE = {
//     DelayBilling: {
//         code: 1,
//         text: '延时计费',
//     },
//     StillBilling: {
//         code: 2,
//         text: '仍在计费判责',
//     },
//     CanNotBilling: {
//         code: 4,
//         text: '无法归还',
//     },
//     Overcharge: {
//         code: 5,
//         text: '多扣费',
//     },
//     BusinessAppeal: {
//         code: 6,
//         text: '商家对订单费用有疑问',
//     },
//     CompleteAppeal: {
//         code: 7,
//         text: '封顶计费申诉',
//     },
//     ORDER_RERURN_APPEAL: {
//         code: 8,
//         text: '归还多收费申诉',
//     },
//     NO_POPING_APPEAL: {
//         code: 9,
//         text: '未弹出仍收费',
//     },
//     CAN_NOT_CHARGE_APPEAL: {
//         code: 10,
//         text: '宝无法充电',
//     },
//     CHARGE_SLOWER_APPEAL: {
//         code: 11,
//         text: '充电慢电量少',
//     },
//     Unknown: {
//         text: '未知类型',
//     },
// };

// const ORDER_RERURN_APPEAL_REASON = {
//     1: '早已归还，订单多计时间', 2: '寻找归还点位耽误还宝', 3: '不认可计价规则', 4: '计价规则与机柜描述不符',
// };
// const APPEAL_UNPASS_REASON = {
//     1: '机柜不存在断电、异物', 2: '不是最近一笔非0元单', 3: '订单暂停计费过', 4: '退款改价标签异常', 5: '订单费用不符合条件', 6: '租借时间过短', 7: '宝故障判责不通过', 8: '宝质量分判责不通过',
// };

// const APPEAL_RESULT_TYPE = [
//     {
//         code: 1,
//         text: '婉拒',
//     },
//     {
//         code: 2,
//         text: '成功',
//     },
//     {
//         code: 200,
//         text: '可以暂停计费，用户未提交暂停计费申请',
//     },
//     {
//         code: 250,
//         text: '可以暂停计费，用户未提交暂停计费申请',
//     },
//     {
//         code: 201,
//         text: '无法暂停计费',
//     },
//     {
//         code: 202,
//         text: '智能系统不处理',
//     },
//     {
//         code: 203,
//         text: '智能系统不处理，申诉类型流转给“已归还，仍在计费”',
//     },
//     {
//         code: 204,
//         text: '二代机，已为用户人工还宝',
//     },
//     {
//         code: 210,
//         text: '无法暂停计费，近期多次暂停',
//     },
//     {
//         code: 211,
//         text: '无法暂停计费，附近有多个可归还点',
//     },
//     {
//         code: 3,
//         text: '人工归还',
//     },
//     {
//         code: 300,
//         text: '已暂停计费',
//     },
//     {
//         code: 350,
//         text: '已暂停计费',
//     },
//     {
//         code: 4,
//         text: '订单暂停计费中',
//     },
//     {
//         code: 41,
//         text: '暂停期间订单已结束',
//     },
//     {
//         code: 42,
//         text: '暂停期间未核实到归还',
//     },
//     {
//         code: 5,
//         text: '延缓处理中',
//     },
//     {
//         code: 51,
//         text: '延缓期间订单已完结',
//     },
//     {
//         code: 52,
//         text: '延缓期间未核实到归还',
//     },
// ];
// const APPEAL_OVER_CHARGE_RESULT_TYPE = [
//     {
//         status: 100,
//         text: '可退款/改价(人工还宝标签正常)',
//     },
//     {
//         status: 101,
//         text: '可退款/改价(暂停计费超时)',
//     },
//     {
//         status: 102,
//         text: '可退款/改价(超过计价{reliefTime}分钟)',
//     },
//     {
//         status: 103,
//         text: '可退款/改价(设备异常)',
//     },
//     {
//         status: 104,
//         text: '可退款/改价(退款标签正常)',
//     },
//     {
//         status: 200,
//         text: '已退款(人工还宝标签正常)',
//     },
//     {
//         status: 201,
//         text: '已退款(暂停计费超时)',
//     },
//     {
//         status: 202,
//         text: '已退款(超过计价{reliefTime}分钟)',
//     },
//     {
//         status: 203,
//         text: '已退款(设备异常)',
//     },
//     {
//         status: 204,
//         text: '已退款(退款标签正常)',
//     },
//     {
//         status: 300,
//         text: '婉拒(人工还宝标签异常)',
//     },
//     {
//         status: 301,
//         text: '婉拒(非最近一笔订单)',
//     },
//     {
//         status: 302,
//         text: '婉拒(退款标签异常)',
//     },
//     {
//         status: 303,
//         text: '婉拒（封顶未检测到宝）',
//     },
//     {
//         status: 304,
//         text: '婉拒（订单金额过高）',
//     },
//     {
//         status: 305,
//         text: '婉拒（改价校验不通过）',
//     },
//     {
//         status: 306,
//         text: '婉拒（改价暂停计费中）',
//     },
//     {
//         status: 401,
//         text: '已改价(暂停计费超时)',
//     },
//     {
//         status: 402,
//         text: '已改价(超过计价{reliefTime}分钟)',
//     },
//     {
//         status: 403,
//         text: '已改价(设备异常)',
//     },
//     {
//         status: 404,
//         text: '已改价(退款标签正常)',
//     },
// ];
// const BUSINESS_APPEAL_RESULT_TYPE = [
//     {
//         status: 1,
//         text: '业务员审核中',
//     },
//     {
//         status: 2,
//         text: '免审通过',
//     },
//     {
//         status: 3,
//         text: '业务员审核通过',
//     },
//     {
//         status: 4,
//         text: '业务员审核拒绝',
//     },
//     {
//         status: 5,
//         text: '命中风控30天多次退款',
//     },
//     {
//         status: 6,
//         text: '业务员超时未审核，自动通过',
//     },
//     {
//         status: 7,
//         text: '业务员超时未审核，自动驳回',
//     },
//     {
//         status: 8,
//         text: '主动购宝',
//     },
//     {
//         status: 9,
//         text: '非商家门店',
//     },
//     {
//         status: 10,
//         text: '业务员审核未通过',
//     },
// ];
// const CANNOT_RETURN_RESASON = {
//     1: '附近找不到门店',
//     2: '不方便归还',
//     3: '充电宝丢失',
//     4: '我已归还，仍在计费',
//     5: '找到机柜已满仓，插反等',
//     6: '已归还到其他品牌的机柜',
// };

//  区域枚举
const AREA_TYPE = {
    Mainland: 0,
    Macao: 1,
    Japan: 2, // 日本项目 添加
};

const PRICE_UNIT = {
    [AREA_TYPE.Mainland]: '￥',
    [AREA_TYPE.Macao]: 'MOP$ ',
    [AREA_TYPE.Japan]: '￥',
};

//  币种枚举
// const CURRENCY_TYPE = {
//     Rmb: 1,
//     Mop: 2,
// };

// const CURRENCY_NAME = {
//     [CURRENCY_TYPE.Rmb]: '人民币',
//     [CURRENCY_TYPE.Mop]: '澳门币',
// };

// const CURRENCY_SYMBOL_UNIT = {
//     [CURRENCY_TYPE.Rmb]: '元',
//     [CURRENCY_TYPE.Mop]: 'MOP',
// };

//券状态
// const COUPON_STATUS = {
//     0: '发劵',
//     1: '发劵',
//     2: '已发劵，未使用',
//     3: '已发劵，已经使用',
//     4: '已发劵，已过期',
// };
const SUSPEND_TIME = [{
    value: 1440,
    name: i18n.t('order.orderConstants.SUSPEND_TIME.24Hours'),
},
// {
//     value: 3,
//     name: '3分钟',
// },
// {
//     value: 6,
//     name: '6分钟',
// },
// {
//     value: 11,
//     name: '11分钟',
// },
//   {
//     value: 2880,
//     name: '48小时',
// }, {
//     value: 4320,
//     name: '72小时',
    // }
];
export {
    SUSPEND_TIME,
    MAX_LENGTH_VALIDATE,
    ORDER_STATUS_QUERY,
    SOURCE,
    SOURCE_MAP,
    COUPON_SOURCE,
    // RIGHTS_TYPE,
    // RIGHTS_INFO,
    ORDER_FORM_FILTER,
    DISABLED_REFUNDE_LIST,
    // ORDER_OPERATE_RIGHT,
    DISABLED_RETURN_LIST,
    // DISABLED_CHANGE_PRICE,
    OPT_TYPE_MAP,
    RESULT_MAP,
    // BATTERY_TYPE_MAP,
    DATEPICKER_SHORTCUTS,
    DISABLED_CLOSE_ORDER_LIST,
    OPT_TYPE_CREATE_PAY_ORDER,
    HEADER_STATUS_STEP_ONE,
    HEADER_STATUS_STEP_TWO,
    // FEED_BACK_TYPE,
    // ORDER_RERURN_APPEAL_REASON,
    // APPEAL_UNPASS_REASON,
    // APPEAL_RESULT_TYPE,
    // CANNOT_RETURN_RESASON,
    // APPEAL_OVER_CHARGE_RESULT_TYPE,
    // BUSINESS_APPEAL_RESULT_TYPE,
    AREA_TYPE,
    PRICE_UNIT,
    // PRICE_UNIT_STR,
    // CURRENCY_TYPE,
    // CURRENCY_NAME,
    // CURRENCY_SYMBOL,
    // CURRENCY_SYMBOL_UNIT,
    // COUPON_STATUS,
    DISABLED_COUPON_LIST,
    DEPOSIT_TYPE,
    DEPOSIT_TYPE_DESC,
    DEPOSIT_TYPE_LIST,
};
