







































































































import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import agentSelect from '../common/agentSelect.vue';

@Component({
    components: {
        agentSelect,
    },
})
export default class agentList extends Vue {
    @State((state) => state.common.uacMenusMap) uacMenusMap: any;

    form = {
        agentId: '',
        poiId: '',
        empId: '',
        accountId: '',
    }

    columns = [
        { title: this.$t('poiList.storeId'), dataIndex: 'id' },
        { title: this.$t('poiList.storeName'), dataIndex: 'poiName' },
        { title: this.$t('poiList.storeType'), dataIndex: 'poiType' },
        { title: this.$t('poiList.benefit'), dataIndex: 'benefit' },
        { title: this.$t('poiList.businessStatus'), dataIndex: 'openStatus' },
        { title: this.$t('poiList.businessHours'), dataIndex: 'openTime' },
        { title: this.$t('poiList.storeAddress'), dataIndex: 'address' },
        { title: this.$t('poiList.personInChargePhoneNumber'), dataIndex: 'contactMobile' },
        { title: this.$t('poiList.cooperationStatus'), dataIndex: 'poiStatus' },
        { title: this.$t('poiList.belongingAgent'), dataIndex: 'bindAgentName' },
        { title: this.$t('poiList.belongingId'), dataIndex: 'bindBdName' },
        { title: this.$t('poiList.account'), dataIndex: 'account' },
        { title: this.$t('poiList.cabinetNumber'), dataIndex: 'cabinNum' },
        { title: this.$t('poiList.currentTreasureNumber'), dataIndex: 'cabinBatteryNum' },
        { title: this.$t('poiList.updateTime'), dataIndex: 'updateTime' },
    ]

    tableData = [];

    total: number = 0;

    pagination = {
        pageNum: 1,
        pageSize: 10,
    }

    defaultAccount: any = [];

    async created() {
        const { accountId, accountName } = this.$route.query;
        if (accountId && accountName) {
            this.form.accountId = accountId as string;
            this.defaultAccount = [{
                id: accountId,
                name: accountName,
            }];
        }
        this.getTableData();
    }

    async getTableData() {
        Object.keys(this.form).forEach((key) => {
            if (this.form[key] === '') {
                this.form[key] = null;
            }
        });
        const { data } = await this.$request.get('/mapi/manager/poi/list-poi', {
            params: {
                ...this.form,
                ...this.pagination,
            },
        });
        if (data && data.data) {
            this.tableData = data.data;
            this.total = data.total;
        } else {
            this.tableData = [];
            this.total = 0;
        }
    }

    edit(row: any) {
        this.$router.push(`/poi/detail?poiId=${row.id}`);
    }

    search() {
        this.pagination = {
            pageNum: 1,
            pageSize: 10,
        };
        this.getTableData();
    }

    change() {
        this.getTableData();
    }

    async exportFile() {
        Object.keys(this.form).forEach((key) => {
            if (this.form[key] === '') {
                this.form[key] = null;
            }
        });
        const { success, data } = await this.$request.get('/mapi/manager/poi/list-poi-export', {
            params: {
                ...this.form,
            },
        });
        if (success && data) {
            window.open(data);
            // this.$mtd.message.success(this.$t('poiList.exportSuccessTip') as any);
        }
    }
}
