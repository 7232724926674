<template>
    <div class="poi-detail">
        <h3>{{ $t('poiDetail.basicInfo') }}</h3>
        <mtd-card class="base">
            <div class="img">
                <img :src="poiDetail.poiUrl" />
                <div
                    class="pic-edit"
                >
                    <mtd-upload
                        class="base-upload"
                        :show-file-list="false"
                        accept="image/*"
                        :http-request="httpRequest"
                    >
                        <span>{{ $t('poiDetail.edit') }}</span>
                    </mtd-upload>
                </div>
            </div>
            <div class="info">
                <mtd-row
                    v-for="(group, index) in baseInfo"
                    :key="index"
                    :gutter="20"
                >
                    <mtd-col
                        v-for="(item, i) in group"
                        :key="item.title"
                        :span="8"
                    >
                        <div>
                            {{ item.title }}:
                            <template v-if="!item.isEditing">
                                <template v-if="item.value === 'address'">
                                    <div class="address">
                                        {{ poiDetail[item.value] }}
                                        <span
                                            v-if="item.canEdit"
                                            class="txt-edit"
                                            @click="infoEdit(index, i)"
                                        >{{ $t('poiDetail.edit') }}</span>
                                    </div>
                                </template>
                                <template v-else>
                                    {{ poiDetail[item.value] }}
                                    <span
                                        v-if="item.canEdit"
                                        class="txt-edit"
                                        @click="infoEdit(index, i)"
                                    >{{ $t('poiDetail.edit') }}</span>
                                </template>
                            </template>
                            <template v-else>
                                <template v-if="item.value === 'typeName'">
                                    <mtd-select v-model="poiDetail.typeId">
                                        <mtd-option
                                            v-for="item in poiTypeList"
                                            :key="item.id"
                                            :label="item.typeName"
                                            :value="item.id"
                                        />
                                    </mtd-select>
                                </template>
                                <template v-else-if="item.value === 'openStatusDesc'">
                                    <mtd-select v-model="poiDetail.openStatus">
                                        <mtd-option
                                            v-for="item in poiStatusEnum"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                        />
                                    </mtd-select>
                                </template>
                                <template v-else-if="item.value === 'address'">
                                    <mtd-textarea
                                        v-model="poiDetail[item.value]"
                                        rows="3"
                                        class="address-textarea"
                                    />
                                </template>
                                <template v-else-if="item.value === 'openTime'">
                                    <mtd-select
                                        v-model="timeConf.start"
                                        class="time-select"
                                        size="small"
                                        popper-class="time-select"
                                    >
                                        <mtd-option
                                            v-for="item in hourList"
                                            :key="item"
                                            :label="item"
                                            :value="item"
                                            class="time-option"
                                        />
                                    </mtd-select>
                                    ~
                                    <mtd-select
                                        v-model="timeConf.end"
                                        class="time-select"
                                        size="small"
                                        popper-class="time-select"
                                    >
                                        <mtd-option
                                            v-for="item in hourList"
                                            :key="item"
                                            :label="item"
                                            :value="item"
                                            class="time-option"
                                        />
                                    </mtd-select>
                                </template>
                                <template v-else>
                                    <mtd-input v-model="poiDetail[item.value]" />
                                </template>
                            </template>
                        </div>
                    </mtd-col>
                </mtd-row>
            </div>
        </mtd-card>
        <h3>{{ $t('poiDetail.cooperationInfo') }}</h3>
        <mtd-card class="coop">
            <div class="info">
                <mtd-row
                    v-for="(group, index) in coopInfo"
                    :key="index"
                    :gutter="20"
                >
                    <mtd-col
                        v-for="(item, i) in group"
                        :key="item.title"
                        :span="12"
                    >
                        <div>
                            {{ item.title }}:
                            <template v-if="!item.isEditing">
                                {{ poiDetail[item.value] || '-' }}
                                <span
                                    v-if="item.canEdit"
                                    class="txt-edit"
                                    @click="coopEdit(index, i)"
                                >{{ $t('poiDetail.edit') }}</span>
                            </template>
                            <template v-else>
                                <template v-if="item.value === 'bindAgentName'">
                                    <agent-select
                                        v-model="poiDetail.bindAgentId"
                                        key-ids="id"
                                        :default-list="[{id: defaultDetail.bindAgentId, name: defaultDetail.bindAgentName}]"
                                    />
                                </template>
                                <template v-else-if="item.value === 'bindBdName'">
                                    <agent-select
                                        v-model="poiDetail.bindBdId"
                                        url="/mapi/manager/user/list-emp-keyword"
                                        key-ids="id"
                                        key-names="managerName"
                                        :default-list="[{id: defaultDetail.bindBdId, managerName: defaultDetail.bindBdName}]"
                                    />
                                </template>
                                <template v-else-if="item.value === 'account'">
                                    <agent-select
                                        v-model="poiDetail.accountId"
                                        url="/mapi/manager/account/list-account-keyword"
                                        key-ids="id"
                                        key-names="account"
                                        :default-list="[{id: defaultDetail.accountId, account: defaultDetail.account}]"
                                    />
                                </template>
                                <template v-else>
                                    <mtd-input v-model="poiDetail[item.value]" />
                                </template>
                            </template>
                        </div>
                    </mtd-col>
                </mtd-row>
            </div>
            <div class="desc">
                <p>{{ $t('poiDetail.remark') }}：</p>
                <mtd-textarea
                    v-model="poiDetail.remark"
                    :placeholder="$t('poiDetail.inputTip')"
                    style="width: 260px;"
                    rows="3"
                    maxlength="50"
                />
            </div>
        </mtd-card>
        <div class="footer">
            <mtd-button
                class="btn-demo-item"
                @click="back"
            >
                {{ $t('poiDetail.return') }}
            </mtd-button>
            <mtd-button
                class="btn-demo-item save"
                type="primary"
                @click="save"
            >
                {{ $t('poiDetail.saveChanges') }}
            </mtd-button>
        </div>
    </div>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator';
import agentSelect from '../common/agentSelect.vue';

@Component({
    components: {
        agentSelect,
    },
})
export default class MyComponent extends Vue {
    poiDetail = {};

    defaultDetail = {};

    // Your code here
    get poiId() {
        const { poiId } = this.$route.query;
        return poiId || '';
    }

    hourList = Array.from({ length: 24 }, (v, i) => `${(i < 10 ? '0' : '') + i}:00`).concat('23:59');

    poiTypeList = [];

    timeConf = {};

    isEditing = false;

    unCoopKey = ['accountEmail', 'account'];

    poiStatusEnum = [{
        label: this.$t('poiDetail.notYetOpen'),
        value: 1,
    }, {
        label: this.$t('poiDetail.suspendBusiness'),
        value: 2,
    }, {
        label: this.$t('poiDetail.inBusiness'),
        value: 3,
    }, {
        label: this.$t('poiDetail.closed'),
        value: 4,
    }];

    baseInfo = [
        [
            {
                title: this.$t('poiDetail.storeName'),
                value: 'poiName',
                canEdit: true,
            },
            {
                title: this.$t('poiDetail.businessStatus'),
                value: 'openStatusDesc',
                canEdit: true,
            },
            {
                title: this.$t('poiDetail.personInChargePhone'),
                value: 'contactMobile',
                canEdit: true,
                rule: /^[+0-9]{6,20}$/,
            },
        ],
        [
            {
                title: this.$t('poiDetail.storeId'),
                value: 'id',
            },
            {
                title: this.$t('poiDetail.businessHours'),
                value: 'openTime',
                canEdit: true,
            },
            {
                title: this.$t('poiDetail.personInChargeEmail'),
                value: 'contactEmail',
                canEdit: true,
                rule: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            },
        ],
        [
            {
                title: this.$t('poiDetail.storeType'),
                value: 'typeName',
                canEdit: true,
            },
            {
                title: this.$t('poiDetail.storeAddress'),
                value: 'address',
                canEdit: false,
            },
        ],
    ];

    coopInfo = [
        [
            {
                title: this.$t('poiDetail.cooperationStatus'),
                value: 'coopStatus',
            },
            {
                title: this.$t('poiDetail.merchantAccount'),
                value: 'account',
                canEdit: false,
            },
        ],
        [
            {
                title: this.$t('poiDetail.belongingAgent'),
                value: 'bindAgentName',
                canEdit: false,
            },
            {
                title: this.$t('poiDetail.merchantEmail'),
                value: 'accountEmail',
            },
        ],
        [
            {
                title: this.$t('poiDetail.belongingBd'),
                value: 'bindBdName',
                canEdit: false,
            },
        ],
    ]

    async mounted() {
        const { data } = await this.$request.get('/mapi/manager/poi/query-poi-detail', {
            params: {
                poiId: this.poiId,
            },
        });
        if (data.coopStatusId === 2) {
            this.coopInfo.forEach((row) => {
                row.forEach((item) => {
                    if (item.canEdit === false) {
                        item.canEdit = true;
                    }
                });
            });
        }
        this.poiDetail = { ...data };
        this.defaultDetail = { ...data };
        if (this.poiDetail.openTime) {
            const arrs = this.poiDetail.openTime.split(' ~ ');
            if (arrs.length === 2) {
                this.timeConf = {
                    start: arrs[0],
                    end: arrs[1],
                };
            }
        }
        this.getPoiTypeList();
    }

    async httpRequest(option) {
        const fileParam = new FormData();
        fileParam.append('file', option.file);
        const { data, success } = await this.$request.post('/mapi/manager/upload/upload-poi-pic', fileParam, {
            headers: {
                'Content-type': 'multipart/form-data',
            },
        });
        if (success && data) {
            this.poiDetail.poiUrl = data.fileUrl;
        }
    }

    back() {
        if (this.isEditing) {
            this.$mtd.confirm({
                title: this.$t('poiDetail.prompt'),
                message: this.$t('poiDetail.saveTip'),
                okButtonText: this.$t('poiDetail.confirmReturn'),
                onOk: () => {
                    this.$router.go(-1);
                },
            });
        } else {
            this.$router.go(-1);
        }
    }

    vaildatePoi() {
        const keys = Object.keys(this.poiDetail);
        const arrs = this.baseInfo.concat(this.coopInfo).flat();
        const { start, end } = this.timeConf;
        const titleMap = {};
        arrs.forEach((item) => {
            titleMap[item.value] = item;
        });
        let isVaild = true;
        this.poiDetail.openTime = `${start} ~ ${end}`;
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const conf = titleMap[key];
            if (this.poiDetail.coopStatusId === 1 && this.unCoopKey.indexOf(key) > -1) {
                break;
            }
            if (conf) {
                if (!this.poiDetail[key]) {
                    this.$mtd.message.error(`${conf.title}${this.$t('poiDetail.required')}`);
                    isVaild = false;
                } else if (conf.rule && !conf.rule.test(this.poiDetail[key])) {
                    this.$mtd.message.error(`${conf.title}${this.$t('poiDetail.unvaild')}`);
                    isVaild = false;
                }
            }
        }
        return isVaild;
    }

    async save() {
        if (!this.vaildatePoi()) {
            return;
        }
        const { data } = await this.$request.post('/mapi/manager/poi/update-poi', {
            ...this.poiDetail,
        });
        if (data) {
            this.$mtd.message.success(this.$t('poiDetail.saveSuccess'));
            this.$router.go(0);
        }
    }

    async getPoiTypeList() {
        const { success, data } = await this.$request.get('/mapi/manager/poi/list-poi-type', {
            params: {
                typeStatus: 1,
            },
        });
        if (success && data) {
            this.poiTypeList = data.data;
        }
    }

    infoEdit(index, i) {
        const info = {
            ...this.baseInfo[index][i],
            isEditing: true,
        };
        this.baseInfo[index].splice(i, 1, info);
        this.isEditing = true;
    }

    coopEdit(index, i) {
        const info = {
            ...this.coopInfo[index][i],
            isEditing: true,
        };
        this.coopInfo[index].splice(i, 1, info);
        this.isEditing = true;
    }
}
</script>
<style lang="less" scoped>
.base {
  overflow: hidden;
  box-sizing: content-box;
  height: 240px;
  position: relative;
  .mtd-card-body {
    overflow: hidden;
    height: 200px;
  }
  .info {
    padding-left: 170px;
    font-size: 14px;
    line-height: 60px;
    .txt-edit {
      color: #0a70f5;
      font-size: 14px;
      padding-left: 5px;
      cursor: pointer;
    }
    .mtd-select {
        padding-top: 15px;
        line-height: 45px;
    }
    .mtd-col {
        position: relative;
    }
    .address {
        line-height: 20px;
        display: inline;
        position: absolute;
        word-break: break-all;
        padding-left: 3px;
        padding-top: 20px;
        width: calc(100% - 60px);
        box-sizing: content-box;
    }
    .address-textarea {
        position: absolute;
        width: calc(100% - 60px);
    }
  }
  .pic-edit {
    position: absolute;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    font-size: 12px;
    padding: 3px 6px;
    border-radius: 3px;
    cursor: pointer;
    .mtd-upload-text {
      color: #fff;
      text-decoration: none;
      &:active {
        color: #fff;
      }
    }
  }
}
.img {
  width: 160px;
  height: 160px;
  overflow: hidden;
  background: #fafafa;
  position: absolute;
  margin-top: 20px;
  img {
    width: 160px;
  }
}
.coop {
  overflow: hidden;
  height: 148px;
  .info {
    position: absolute;
    width: 700px;
    line-height: 36px;
  }
  .desc {
    padding-left: 720px;
    p {
      margin: 7px 0;
    }
  }
  .txt-edit {
    color: #0a70f5;
    font-size: 14px;
    padding-left: 5px;
    cursor: pointer;
  }
}
.footer {
  margin-top: 40px;
  text-align: center;
  .save {
    margin-left: 30px;
  }
}
.time-select {
    width: 100px;
}
</style>
<style lang="less">
.time-select {
    .mtd-dropdown-menu {
        min-width: 120px;
    }
}
.time-option {
    width: 120px;
}
</style>
