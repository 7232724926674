<template>
    <div class="order-module">
        <mtd-main>
            <div class="order-filter-opt">
                <mtd-form
                    ref="ruleForm"
                    :rules="rules"
                    :model="orderForm"
                    :label-width="80"
                    :class="{collapse}"
                    inline
                >
                    <mtd-row :gutter="24">
                        <mtd-col :span="6">
                            <mtd-form-item
                                :label="$t('order.orderList.searchForm.orderNumber')"
                                prop="orderId"
                            >
                                <mtd-input
                                    v-model="orderForm.orderId"
                                    :placeholder="$t('order.orderList.searchForm.pleaseInputPh')"
                                    type="text"
                                />
                            </mtd-form-item>
                        </mtd-col>
                        <mtd-col :span="6">
                            <mtd-form-item
                                :label="$t('order.orderList.searchForm.phoneNumber')"
                                prop="mobile"
                            >
                                <mtd-input
                                    v-model="orderForm.mobile"
                                    :placeholder="$t('order.orderList.searchForm.pleaseInputPh')"
                                    type="text"
                                />
                            </mtd-form-item>
                        </mtd-col>
                        <mtd-col :span="6">
                            <mtd-form-item :label="$t('order.orderList.searchForm.orderStatus')">
                                <mtd-select
                                    v-model="orderForm.status"
                                    clearable
                                    :placeholder="$t('order.orderList.searchForm.pleaseSelectPh')"
                                >
                                    <mtd-option
                                        v-for="item in statusQueryOptions"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    />
                                </mtd-select>
                            </mtd-form-item>
                        </mtd-col>
                        <mtd-col
                            v-if="collapse"
                            :span="6"
                        >
                            <mtd-form-item>
                                <mtd-button
                                    type="primary"
                                    class="btn-filter-opt"
                                    @click="search"
                                >
                                    {{ $t('order.orderList.searchForm.search') }}
                                </mtd-button>
                                <mtd-button
                                    class="btn-filter-opt"
                                    @click="resetFilter"
                                >
                                    {{ $t('order.orderList.searchForm.reset') }}
                                </mtd-button>
                                <mtd-button
                                    class="btn-filter-opt"
                                    type="text-primary"
                                    @click="toggle"
                                >
                                    {{ collapse ? $t('order.orderList.searchForm.expand') : $t('order.orderList.searchForm.retract') }}
                                </mtd-button>
                            </mtd-form-item>
                        </mtd-col>
                        <mtd-col
                            v-else
                            :span="6"
                        >
                            <!-- 押金类型 -->
                            <mtd-form-item :label="$t('order.orderList.searchForm.depositType')">
                                <mtd-select
                                    v-model="orderForm.payMode"
                                    clearable
                                    :placeholder="$t('order.orderList.searchForm.pleaseSelectPh')"
                                >
                                    <mtd-option
                                        v-for="item in DEPOSIT_TYPE_LIST"
                                        :key="item.value"
                                        :value="item.value"
                                        :label="item.label"
                                    />
                                </mtd-select>
                            </mtd-form-item>
                        </mtd-col>
                    </mtd-row>
                    <mtd-row :gutter="24">
                        <!-- <mtd-col :span="6">
                          <mtd-form-item
                              label="用户名"
                              prop="username"
                          >
                              <mtd-input
                                  v-model="orderForm.username"
                                  placeholder="请输入用户名"
                                  type="text"
                              />
                          </mtd-form-item>
                      </mtd-col> -->
                        <mtd-col :span="6">
                            <mtd-form-item
                                :label="$t('order.orderList.searchForm.userId')"
                                prop="userId"
                            >
                                <mtd-input
                                    v-model="orderForm.userId"
                                    :placeholder="$t('order.orderList.searchForm.pleaseInputPh')"
                                    type="text"
                                />
                            </mtd-form-item>
                        </mtd-col>
                        <!-- <mtd-col :span="6">
                          <mtd-form-item label="订单来源">
                              <mtd-select
                                  v-model="orderForm.source"
                                  clearable
                              >
                                  <mtd-option
                                      :value="0"
                                      label="line小程序"
                                  />
                                  <mtd-option
                                      :value="1"
                                      label="APP"
                                  />
                              </mtd-select>
                          </mtd-form-item>
                      </mtd-col> -->
                        <mtd-col :span="6">
                            <mtd-form-item
                                :label="$t('order.orderList.searchForm.lendPoi')"
                                prop="lendPoiId"
                            >
                                <mtd-input
                                    v-model="orderForm.lendPoiId"
                                    :placeholder="$t('order.orderList.searchForm.pleaseInputPh')"
                                    type="text"
                                />
                            </mtd-form-item>
                        </mtd-col>
                        <mtd-col :span="6">
                            <mtd-form-item
                                :label="$t('order.orderList.searchForm.returnPoi')"
                                prop="returnPoiId"
                            >
                                <mtd-input
                                    v-model="orderForm.returnPoiId"
                                    :placeholder="$t('order.orderList.searchForm.pleaseInputPh')"
                                    type="text"
                                />
                            </mtd-form-item>
                        </mtd-col>
                        <mtd-col :span="6">
                            <mtd-form-item
                                :label="$t('order.orderList.searchForm.batterySn')"
                                prop="batterySn"
                            >
                                <mtd-input
                                    v-model="orderForm.batterySn"
                                    :placeholder="$t('order.orderList.searchForm.pleaseInputPh')"
                                    type="text"
                                />
                            </mtd-form-item>
                        </mtd-col>
                    </mtd-row>
                    <mtd-row :gutter="24">
                        <mtd-col :span="6">
                            <mtd-form-item
                                :label="$t('order.orderList.searchForm.lendCabinSn')"
                                prop="lendCabinSn"
                            >
                                <mtd-input
                                    v-model="orderForm.lendCabinSn"
                                    :placeholder="$t('order.orderList.searchForm.pleaseInputPh')"
                                    type="text"
                                />
                            </mtd-form-item>
                        </mtd-col>
                        <mtd-col :span="6">
                            <mtd-form-item
                                :label="$t('order.orderList.searchForm.returnCabinSn')"
                                prop="returnCabinSn"
                            >
                                <mtd-input
                                    v-model="orderForm.returnCabinSn"
                                    :placeholder="$t('order.orderList.searchForm.pleaseInputPh')"
                                    type="text"
                                />
                            </mtd-form-item>
                        </mtd-col>
                        <mtd-col :span="6">
                            <mtd-form-item :label="$t('order.orderList.searchForm.lendPoiName')">
                                <mtd-select
                                    v-model="orderForm.lendPoiName"
                                    :debounce="1000"
                                    :remote-method="getSugPoiList.bind(this, 'lend')"
                                    clearable
                                    filterable
                                    remote
                                    :placeholder="$t('order.orderList.searchForm.pleaseSelectPh')"
                                    @change="selectLendPoiName"
                                >
                                    <mtd-option
                                        v-for="item in lendPoiList"
                                        :key="item.poiId"
                                        :label="item.poiName"
                                        :value="item.poiName"
                                    />
                                </mtd-select>
                            </mtd-form-item>
                        </mtd-col>
                        <mtd-col :span="6">
                            <mtd-form-item :label="$t('order.orderList.searchForm.returnPoiName')">
                                <mtd-select
                                    v-model="orderForm.returnPoiName"
                                    :debounce="1000"
                                    :remote-method="getSugPoiList.bind(this, 'return')"
                                    clearable
                                    filterable
                                    remote
                                    :placeholder="$t('order.orderList.searchForm.pleaseSelectPh')"
                                    @change="selectReturnPoiName"
                                >
                                    <mtd-option
                                        v-for="item in returnPoiList"
                                        :key="item.poiId"
                                        :label="item.poiName"
                                        :value="item.poiName"
                                    />
                                </mtd-select>
                            </mtd-form-item>
                        </mtd-col>
                    </mtd-row>
                    <mtd-row :gutter="24">
                        <mtd-col :span="6">
                            <mtd-form-item :label="$t('order.orderList.searchForm.createOrderTime')">
                                <mtd-date-picker
                                    v-model="orderForm.createTimeRange"
                                    :default-time="['00:00:00','23:59:59']"
                                    :options="datePickerOptions"
                                    type="datetimerange"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    value-format="timestamp"
                                    clearable
                                    :placeholder="$t('order.orderList.searchForm.pleaseSelectPh')"
                                />
                            </mtd-form-item>
                        </mtd-col>
                        <mtd-col :span="6">
                            <mtd-form-item :label="$t('order.orderList.searchForm.returnTime')">
                                <mtd-date-picker
                                    v-model="orderForm.returnTimeRange"
                                    :default-time="['00:00:00','23:59:59']"
                                    :options="datePickerOptions"
                                    type="datetimerange"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    value-format="timestamp"
                                    clearable
                                    :placeholder="$t('order.orderList.searchForm.pleaseSelectPh')"
                                />
                            </mtd-form-item>
                        </mtd-col>
                        <mtd-col :span="6">
                            <!-- <mtd-form-item
                              label="宝SN"
                              prop="batterySn"
                          >
                              <mtd-input
                                  v-model="orderForm.batterySn"
                                  placeholder="请输入充电宝SN"
                                  type="text"
                              />
                          </mtd-form-item> -->
                        </mtd-col>
                        <mtd-col :span="6">
                            <mtd-form-item>
                                <mtd-button
                                    type="primary"
                                    class="btn-filter-opt"
                                    @click="search"
                                >
                                    {{ $t('order.orderList.searchForm.search') }}
                                </mtd-button>
                                <mtd-button
                                    class="btn-filter-opt"
                                    @click="resetFilter"
                                >
                                    {{ $t('order.orderList.searchForm.reset') }}
                                </mtd-button>
                                <mtd-button
                                    class="btn-filter-opt"
                                    type="text-primary"
                                    @click="toggle"
                                >
                                    {{ collapse ? $t('order.orderList.searchForm.expand') : $t('order.orderList.searchForm.retract') }}
                                </mtd-button>
                            </mtd-form-item>
                        </mtd-col>
                        <!-- <mtd-col :span="6">
                          <mtd-form-item label="支付时间">
                              <mtd-date-picker
                                  v-model="orderForm.payTimeRange"
                                  :default-time="['00:00:00','23:59:59']"
                                  :options="datePickerOptions"
                                  type="datetimerange"
                                  format="yyyy-MM-dd HH:mm:ss"
                                  value-format="timestamp"
                                  clearable
                                  placeholder="开始时间-结束时间"
                              />
                          </mtd-form-item>
                      </mtd-col> -->
                    </mtd-row>
                    <mtd-row :gutter="24">
                        <!-- <mtd-col :span="6">
                          <mtd-form-item label="弹宝时间">
                              <mtd-date-picker
                                  v-model="orderForm.popUpTimeRange"
                                  :default-time="['00:00:00','23:59:59']"
                                  :options="datePickerOptions"
                                  type="datetimerange"
                                  format="yyyy-MM-dd HH:mm:ss"
                                  value-format="timestamp"
                                  clearable
                                  placeholder="开始时间-结束时间"
                              />
                          </mtd-form-item>
                      </mtd-col> -->
                    </mtd-row>
                </mtd-form>
            </div>
            <div class="table-box">
                <mtd-table
                    :data="tableData"
                    :tooltip-props="{ size: 'small' }"
                    :loading="dataLoading"
                >
                    <mtd-table-column
                        :label="$t('order.orderList.tableData.orderId')"
                        width="230"
                        prop="orderId"
                        fixed
                        show-overflow-tooltip
                    />
                    <mtd-table-column
                        :label="$t('order.orderList.tableData.userId')"
                        prop="userId"
                        width="150"
                    />
                    <mtd-table-column
                        :label="$t('order.orderList.tableData.createTime')"
                        width="180"
                        prop="createTime"
                    />
                    <mtd-table-column
                        :label="$t('order.orderList.tableData.returnTime')"
                        width="180"
                        prop="returnTime"
                    />
                    <mtd-table-column
                        :label="$t('order.orderList.tableData.status')"
                        prop="status"
                    />
                    <!-- 押金类型 -->
                    <mtd-table-column
                        :label="$t('order.orderList.tableData.payMode')"
                        prop="payMode"
                    >
                        <template #default="scope">
                            <span v-if="!scope.row.payMode">-</span>
                            <mtd-tag
                                v-else-if="scope.row.payMode === DEPOSIT_TYPE.DEPOSIT"
                                theme="green"
                            >
                                {{ DEPOSIT_TYPE_DESC.DEPOSIT }}
                            </mtd-tag>
                            <mtd-tag
                                v-else-if="scope.row.payMode === DEPOSIT_TYPE.NO_REFUND"
                                theme="orange"
                            >
                                {{ DEPOSIT_TYPE_DESC.NO_REFUND }}
                            </mtd-tag>
                        </template>
                    </mtd-table-column>
                    <mtd-table-column
                        :label="$t('order.orderList.tableData.actualFee')"
                        width="150"
                        prop="actualFee"
                    />
                    <mtd-table-column
                        :label="$t('order.orderList.tableData.lendPoiName')"
                        width="200"
                        show-overflow-tooltip
                        prop="lendPoiName"
                    >
                        <template #default="scope">
                            <p>{{ scope.row.lendPoiName || '-' }}</p>
                            <p>{{ scope.row.lendPoiId || '-' }}</p>
                        </template>
                    </mtd-table-column>
                    <mtd-table-column
                        :label="$t('order.orderList.tableData.returnPoiName')"
                        width="200"
                        show-overflow-tooltip
                        prop="returnPoiName"
                    >
                        <template #default="scope">
                            <p>{{ scope.row.returnPoiName || '-' }}</p>
                            <p>{{ scope.row.returnPoiId || '-' }}</p>
                        </template>
                    </mtd-table-column>
                    <mtd-table-column
                        :label="$t('order.orderList.tableData.hand')"
                        fixed="right"
                    >
                        <template #default="scope">
                            <mtd-button
                                size="small"
                                type="text-primary"
                                @click="toDetail(scope.row)"
                            >
                                {{ $t('order.orderList.tableData.check') }}
                            </mtd-button>
                        </template>
                    </mtd-table-column>
                </mtd-table>
                <mtd-pagination
                    :total="total"
                    :page-size="pageSize"
                    :current-page.sync="pageNum"
                    show-total
                    show-quick-jumper
                    class="pagination"
                />
            </div>
        </mtd-main>
    </div>
</template>

<script>
import utils from '@/common/util';
import STATUS from '@/constants/status';
import {
    MAX_LENGTH_VALIDATE,
    ORDER_STATUS_QUERY,
    ORDER_FORM_FILTER,
    DATEPICKER_SHORTCUTS,
    PRICE_UNIT,
    AREA_TYPE,
    DEPOSIT_TYPE,
    DEPOSIT_TYPE_DESC,
    DEPOSIT_TYPE_LIST,
} from '@/constants/orderConstants';
import OrderServiceApi from '@/api/order/orderService';

export default {
    data() {
        return {
            DEPOSIT_TYPE,
            DEPOSIT_TYPE_DESC,
            DEPOSIT_TYPE_LIST,
            statusQueryOptions: ORDER_STATUS_QUERY,
            orderForm: JSON.parse(JSON.stringify(ORDER_FORM_FILTER)), // 表单数据
            lendPoiList: [], // 租借门店list
            returnPoiList: [], // 归还门店list
            tableData: [],
            dataLoading: false,
            pageNum: 1,
            pageSize: 20,
            total: 0,
            collapse: true,
            rules: {
                orderId: [MAX_LENGTH_VALIDATE],
                mobile: [MAX_LENGTH_VALIDATE],
                username: [MAX_LENGTH_VALIDATE],
                userId: [MAX_LENGTH_VALIDATE],
                lendCabinSn: [MAX_LENGTH_VALIDATE],
                returnCabinSn: [MAX_LENGTH_VALIDATE],
                lendPoiId: [MAX_LENGTH_VALIDATE],
                returnPoiId: [MAX_LENGTH_VALIDATE],
                batterySn: [MAX_LENGTH_VALIDATE],
            },
            datePickerOptions: {
                shortcuts: DATEPICKER_SHORTCUTS,
                disabledDate(date) {
                    return date && date.getTime() < (Date.now() - 3600 * 1000 * 24 * 90);
                },
            },
        };
    },
    watch: {
        pageNum() {
            this.getTableData();
        },
    },
    mounted() {
        this.getTableData();
    },
    methods: {
        parseStatusText(cellValue) {
            if (!cellValue) return '-';
            const { length } = ORDER_STATUS_QUERY;
            for (let i = 0; i < length; i++) {
                const valueArr = ORDER_STATUS_QUERY[i].value.split(',').map((p) => +p);
                if (valueArr.includes(cellValue)) {
                    return ORDER_STATUS_QUERY[i].label;
                }
            }
            return '-';
        },
        async getSugPoiList(type, query) {
            try {
                const res = await this.$request.get('/mapi/manager/poi/query-poi-keyword', {
                    params: {
                        keyword: query,
                    },
                });
                const { data } = res;
                if (data && data.length) {
                    const poiList = data.map((item) => ({
                        ...item,
                        label: item.poiName,
                        value: item.poiId,
                    }));
                    if (type === 'lend') {
                        this.lendPoiList = poiList;
                    } else {
                        this.returnPoiList = poiList;
                    }
                } else {
                    if (type === 'lend') {
                        this.lendPoiList = [];
                    } else {
                        this.returnPoiList = [];
                    }
                }
            } catch (e) {
                // this.$mtd.message({
                //     message: 'error',
                //     type: 'error',
                // });
            }
        },
        selectLendPoiName(value) {
            this.selectPoiName('lend', value);
        },
        selectReturnPoiName(value) {
            this.selectPoiName('return', value);
        },
        selectPoiName(type, value) { // 选中或清空门店名称时，自动选择或删除对应的POID
            if (!value) {
                this.orderForm.lendPoiId = null;
                return;
            }

            if (type === 'lend') {
                const res = this.lendPoiList.filter((item) => (item.poiName === value));
                this.orderForm.lendPoiId = res[0].poiId;
            } else if (type === 'return') {
                const res = this.returnPoiList.filter((item) => (item.poiName === value));
                this.orderForm.returnPoiId = res[0].poiId;
            }
        },
        transTimeRange(obj, rangList) {
            rangList.forEach((item) => {
                const { val, start, end } = item;
                if (val.length) {
                    obj[start] = val[0];
                    obj[end] = val[1];
                }
            });
        },
        async getTableData(setPage) {
            try {
                if (setPage) { // 查询按钮触发需要重置页码为第一页
                    this.pageNum = 1;
                }
                this.dataLoading = true;
                const formData = {}; // 处理数据，为空的字段去除
                Object.keys(this.orderForm).forEach((item) => {
                    if (!item.includes('TimeRange') && this.orderForm[item] !== null && this.orderForm[item] !== '') {
                        formData[item] = this.orderForm[item];
                    }
                });
                const {
                    createTimeRange, payTimeRange, popUpTimeRange, returnTimeRange, status: orderStatus,
                } = this.orderForm;
                const rangeList = [{
                    val: createTimeRange, start: 'createStartTime', end: 'createEndTime',
                }, {
                    val: payTimeRange, start: 'payStartTime', end: 'payEndTime',
                }, {
                    val: popUpTimeRange, start: 'popUpStartTime', end: 'popUpEndTime',
                }, {
                    val: returnTimeRange, start: 'returnStartTime', end: 'returnEndTime',
                }];
                this.transTimeRange(formData, rangeList);
                if (orderStatus) { // status 入参处理
                    formData.status = orderStatus.split(',').map((p) => +p);
                }
                const queryParams = {
                    ...formData,
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                };
                const res = await OrderServiceApi.getOrderListData(queryParams);
                const { code, data } = res;
                if (code === STATUS.SUCCESS && data) {
                    this.total = data.totalCount;
                    this.tableData = this.formData(data.data);
                }
            } catch (e) {
                // this.$mtd.message({
                //     message: 'error',
                //     type: 'error',
                // });
            } finally {
                this.dataLoading = false;
            }
        },
        // 格式化订单数据
        formData(dataList = []) {
            return dataList.map((item) => {
                const {
                    createTime, returnTime, orderStatus, consumeFee, areaType,
                } = item;
                return {
                    ...item,
                    status: this.parseStatusText(orderStatus),
                    returnTime: returnTime ? utils.parseTimeToDate(returnTime, 'YYYY-MM-DD HH:mm:ss') : '-',
                    createTime: createTime ? utils.parseTimeToDate(createTime, 'YYYY-MM-DD HH:mm:ss') : '-',
                    actualFee: `${PRICE_UNIT[areaType || AREA_TYPE.Japan]} ${utils.formatPrice(consumeFee)}`,
                };
            });
        },
        resetFilter() { // 重置过滤条件
            this.$set(this, 'orderForm', { ...JSON.parse(JSON.stringify(ORDER_FORM_FILTER)) });
            this.getTableData(true);
        },
        search() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.getTableData(true);
                }
            });
        },
        toggle() {
            this.collapse = !this.collapse;
        },
        toDetail(row) { // 跳转到订单详情页面
            console.log(row);
            const routerUrl = this.$router.resolve({
                name: 'orderDetail',
                params: {
                    orderId: row.orderId,
                    userId: row.userId,
                },
            });

            console.log(routerUrl);

            window.open(routerUrl.href, '_blank');
        },
    },
};
</script>

<style lang="less" scoped>
.order-module {
.btn-filter-opt {
  margin-right: 10px;
}
.mtd-form{
  &.collapse {
    overflow: hidden;
    height: 45px;
  }
}
.pagination {
  float: right;
  margin-top: 20px;
}
}
</style>
