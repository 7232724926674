import Vue from 'vue';

import VueI18n from 'vue-i18n';

import MTD from '@ss/mtd-vue';

import zhCN from '../config/lang/zh-CN';
// import en from '../config/lang/en';
import ja from '../config/lang/ja';

Vue.use(VueI18n);

export const langList = ['ja', 'zh'];

const curLang = window.localStorage.getItem('locale') || window.navigator.language;

// 默认展示日语
const navLang = langList.includes(curLang) ? curLang : 'zh';

const conf = {
    zh: { ...zhCN },
    // en: { ...en },
    ja: { ...ja },
};

(MTD as any).locale(conf[navLang]);

const i18n = new VueI18n({
    locale: navLang, // 设置地区
    messages: conf, // 设置地区信息
});

export default i18n;
