import { request, registHook } from './request';

import store from '../store/index';

import { indexTypes as types } from '../store/action-types';

const exceptList = ['/login', '/downloadapp'];

const urlInList = (url: string, list: Array<string>) => {
    let result = false;
    list.forEach((item: string) => {
        if (url.startsWith(item)) {
            result = true;
        }
    });
    return result;
};

export const login = async () => {
    if (!urlInList(window.location.pathname, exceptList)) {
        const value = window.localStorage.getItem('manager-token');
        if (value) {
            registHook([(req: any) => {
                if (req && req.headers) {
                    req.headers['manager-token'] = value;
                }
                return req;
            }]);
        }
        const { error, data } = await request.get('/mapi/manager/user/query-user-info');
        if (!error && data) {
            // 将获取到的数据存储到vuex中
            store.commit(types.GET_USER_INFO, data);
        }
    }
};
