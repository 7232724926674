<template>
    <div class="order-flow-comp">
        <p class="comp-title">
            {{ $t('order.orderDetail.orderFlow') }}
        </p>
        <mtd-steps
            :active="flowData.currentProgress"
            space="25%"
        >
            <mtd-step
                v-for="(item, index) in stepsList"
                :key="index"
                :title="item.title"
                :status="getProgressStatus(index)"
            >
                <template #description>
                    <div>
                        <p>{{ item.desc }}</p>
                        <p v-if="item.lendPeriod">
                            {{ item.lendPeriod }}
                        </p>
                    </div>
                </template>
            </mtd-step>
        </mtd-steps>
    </div>
</template>

<script>
import utils from '@/common/util';
import { HEADER_STATUS_STEP_ONE, HEADER_STATUS_STEP_TWO } from '@/constants/orderConstants';

export default {
    name: 'OrderFlow',
    props: {
        flowData: {
            type: Object,
            default: () => ({}),
        },
        headerStatus: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        stepsList() {
            const {
                createTime,
                popUpTime,
                returnTime,
                finishTime,
                lendPeriod,
                // buyBatteryTime, // 主动购宝时间
            } = this.flowData;
            const getDesc = (time) => (time ? utils.parseTimeToDate(time, 'YYYY-MM-DD HH:mm:ss') : '-');
            // 租借流程
            console.log('createTime', this.flowData);
            const stepsLease = [{
                title: this.$t('order.orderDetail.orderFlowStep1'), desc: getDesc(createTime),
            }, {
                title: this.$t('order.orderDetail.orderFlowStep2'), desc: getDesc(popUpTime), lendPeriod: `${this.$t('order.orderDetail.leaseDuration')}:${lendPeriod ? utils.formatDuring(lendPeriod) : '-'}`,
            }, {
                title: this.$t('order.orderDetail.orderFlowStep3'), desc: getDesc(returnTime),
            }, {
                title: this.$t('order.orderDetail.orderFlowStep4'), desc: getDesc(finishTime),
            }];
            // 主动买宝流程
            // const stepsBay = [{
            //     title: '创建订单', desc: getDesc(createTime),
            // }, {
            //     title: '弹出成功', desc: getDesc(popUpTime), lendPeriod: `租借时长:${lendPeriod ? utils.formatDuring(lendPeriod) : '-'}`,
            // }, {
            //     title: '购宝', desc: getDesc(buyBatteryTime),
            // }, {
            //     title: '完成', desc: getDesc(finishTime),
            // }, {
            //     title: '归还成功', desc: getDesc(returnTime),
            // }];
            // return buyBatteryTime ? stepsBay : stepsLease;
            return stepsLease;
        },
    },
    methods: {
        getProgressStatus(index) {
            if (index === this.flowData.currentProgress) {
                if (HEADER_STATUS_STEP_TWO.indexOf(this.headerStatus) > -1) {
                    return 'error';
                } else {
                    return 'wait';
                }
            } else {
                if (index === 0 && this.headerStatus === HEADER_STATUS_STEP_ONE) {
                    return 'error';
                } else {
                    return '';
                }
            }
        },
    },
};
</script>

<style lang="less" scoped>
  .order-flow-comp {
    width: 100%;
    .comp-title {
      margin-bottom: 10px;
      font-size: 16px;
      color: #000;
    }
  }
</style>
