


















































































































































import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import dayjs from '@/common/dayjs';
// eslint-disable-next-line import/extensions
import config from '@/common/config';

@Component({ components: {} })
export default class agentList extends Vue {
    @State((state) => state.common.user) user: any;

    form: any = {
        date: '',
        tradeId: '',
        poiId: '',
    }

    tableData = [];

    total: number = 0;

    pagination = {
        pageNum: 1,
        pageSize: 10,
    }

    startDate = dayjs().subtract(1, 'month').toDate();

    dateOptions = {
        disabledDate(date: any) {
            return date && date.getTime() > Date.now();
        },
    };

    rule = {
        date: [{
            required: true, type: 'array', message: this.$t('cashoutList.required'), trigger: 'change',
        }],
    };

    async created() {
        // this.getTableData();
    }

    handlePicker(data: any) {
        const { from, selecting } = data;
        if (!selecting) {
            this.dateOptions = {
                disabledDate(date: any) {
                    return date && date.getTime() > Date.now();
                },
            };
        } else if (from) {
            this.dateOptions = {
                disabledDate(date: any) {
                    return (date && date.getTime() > Date.now()) || (date && date > dayjs(from).add(2, 'month').toDate()) || (date && date < dayjs(from).subtract(2, 'month').toDate());
                },
            };
        }
    }

    async getTableData() {
        this.hadleFormData();
        const { data, success } = await this.$request.post(`${config.billBaseURL}/settle-api/cash-out/page`, {
            ...this.form,
            date: null,
            ...this.pagination,
        });
        if (success && data.content) {
            this.tableData = data.content;
            this.total = data.totalCount;
        } else {
            this.tableData = [];
            this.total = 0;
        }
    }

    hadleFormData() {
        Object.keys(this.form).forEach((key) => {
            if (this.form[key] === '') {
                this.form[key] = null;
            }
        });
        if (this.form.date && this.form.date.length === 2) {
            this.form.startDateTime = dayjs(this.form.date[0]).valueOf();
            this.form.endDateTime = dayjs(dayjs(this.form.date[1]).format('YYYY-MM-DD 23:59:59')).valueOf();
        }
    }

    async exportData() {
        (this.$refs.form as any).validate(async (result: boolean) => {
            if (result) {
                this.hadleFormData();
                const { code, data } = await this.$request.post(`${config.billBaseURL}/settle-api/cash-out/export`, {
                    ...this.form,
                    date: null,
                });
                if (code === 0 && data) {
                    window.open(data);
                }
            }
        });
    }

    search() {
        (this.$refs.form as any).validate((result: boolean) => {
            if (result) {
                this.pagination.pageNum = 1;
                this.getTableData();
            }
        });
    }

    change() {
        (this.$refs.form as any).validate((result: boolean) => {
            if (result) {
                this.getTableData();
            }
        });
    }
}
