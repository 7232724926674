<template>
    <div class="pay-info-comp">
        <p class="comp-title">
            {{ $t('order.orderDetail.payInfo') }}
        </p>
        <mtd-row>
            <mtd-col :span="6">
                <span>{{ $t('order.orderDetail.orderType') }}：</span>
                <mtd-tag
                    v-if="transferData.payMode === 1"
                    theme="green"
                >
                    {{ DEPOSIT_TYPE_DESC.DEPOSIT }}
                </mtd-tag>
                <mtd-tag
                    v-else-if="transferData.payMode === 2"
                    theme="orange"
                >
                    {{ DEPOSIT_TYPE_DESC.NO_REFUND }}
                </mtd-tag>
            </mtd-col>
            <!-- <mtd-col :span="6">
                <span>渠道来源：</span>
                <mtd-tag>{{ transferData.source }}</mtd-tag>
            </mtd-col> -->
            <mtd-col :span="6">
                <span>{{ $t('order.orderDetail.depositFee') }}：</span>
                <span>{{ priceUnit }} {{ transferData.depositFee }}</span>
                <!-- <span v-if="summaryData.areaType">(￥{{ transferData.cnyDepositFee }})</span> -->
            </mtd-col>
            <mtd-col :span="6">
                <span>{{ $t('order.orderDetail.depositRefundFee') }}：</span>
                <span>{{ priceUnit }} {{ transferData.depositRefundFee }}</span>
                <!-- <span v-if="summaryData.areaType">(￥{{ transferData.cnyDepositRefundFee }})</span> -->
            </mtd-col>
            <mtd-col :span="6">
                <span>{{ $t('order.orderDetail.payTpye') }}：</span>
                <span>{{ transferData.payTypeDesc }}</span>
            </mtd-col>
        </mtd-row>
        <mtd-row>
            <mtd-col :span="6">
                <span>{{ $t('order.orderDetail.originFee') }}：</span>
                <span>{{ priceUnit }} {{ transferData.originFee }}</span>
                <!-- <span v-if="summaryData.areaType">(￥{{ transferData.cnyOriginFee }})</span> -->
            </mtd-col>
            <mtd-col :span="6">
                <span>{{ $t('order.orderDetail.needPayFee') }}：</span>
                <span>{{ priceUnit }} {{ transferData.needPayFee }}</span>
                <!-- <span v-if="summaryData.areaType">(￥{{ transferData.cnyNeedPayFee }})</span> -->
            </mtd-col>
            <!-- ！去掉了 -->
            <!-- <mtd-col :span="6">
                <span>优惠金额：</span>
                <span>{{ priceUnit }} {{ transferData.reducedFee }}</span>
            </mtd-col> -->
            <mtd-col :span="6">
                <span>{{ $t('order.orderDetail.manualRefundFee') }}：</span>
                <span>{{ priceUnit }} {{ transferData.manualRefundFee }}</span>
                <!-- <span v-if="summaryData.areaType">(￥{{ transferData.cnyManualRefundFee }})</span> -->
            </mtd-col>
            <mtd-col :span="6">
                <span>{{ $t('order.orderDetail.actualFee') }}：</span>
                <span>{{ priceUnit }} {{ transferData.actualFee }}</span>
                <!-- <span v-if="summaryData.areaType">(￥{{ transferData.cnyActualFee }})</span> -->
            </mtd-col>
        </mtd-row>
        <!-- <mtd-row> -->
        <!-- <mtd-col :span="6">
                <span>优惠类型：</span>
                <span>{{ transferData.couponType }}</span>
            </mtd-col> -->
        <!-- <mtd-col :span="6">
                <span>券权益：</span>
                <span>{{ transferData.couponRight || '-' }}</span>
            </mtd-col>
            <mtd-col :span="6">
                <span>券ID：</span>
                <span>{{ transferData.couponId || '-' }}</span>
            </mtd-col>
            <mtd-col :span="6">
                <span>券来源：</span>
                <span>{{ transferData.couponSourceName }}</span>
            </mtd-col> -->
        <!-- </mtd-row> -->
        <mtd-row>
            <mtd-col :span="6">
                <span>{{ $t('order.orderDetail.leaseDuration') }}：</span>
                <span>{{ transferData.lendPeriod }}</span>
            </mtd-col>
            <mtd-col :span="6">
                <span>{{ $t('order.orderDetail.chargingPeriod') }}：</span>
                <span>{{ transferData.chargingPeriod }}</span>
            </mtd-col>
            <mtd-col :span="12">
                <span>{{ $t('order.orderDetail.chargingRule') }}：</span>
                <!-- <span v-if="activityType === ACTIVITY_TYPE.yiyuanbao">
                    <span style="text-decoration: line-through">{{ activityStatus !== YIYUANBAO_ACTIVITY_STATUS.FAIL ? transferData.chargingRule : ' 活动一口价：1元' }}</span>
                    <span>{{ activityStatus === YIYUANBAO_ACTIVITY_STATUS.FAIL ? transferData.chargingRule : ' 活动一口价：1元' }}</span>
                </span> -->

                <!-- <span v-if="activityType === ACTIVITY_TYPE.qishoubao && activityStatus === QISHOUBAO_ACTIVITY_STATUS.SUCCESS">
                    <span>骑手免费用宝活动订单，前24小时免费，超24小时后，{{ transferData.chargingRule }}</span>
                </span> -->

                <span>{{ transferData.chargingRule }}</span>
            </mtd-col>
        </mtd-row>
    </div>
</template>

<script>
import utils from '@/common/util';
import {
    SOURCE_MAP, PRICE_UNIT, AREA_TYPE, DEPOSIT_TYPE,
    DEPOSIT_TYPE_DESC,
} from '@/constants/orderConstants';
import {
    ACTIVITY_TYPE, YIYUANBAO_ACTIVITY_STATUS, QISHOUBAO_ACTIVITY_STATUS,
} from '@/constants/adConstants';

export default {
    name: 'LendInfo',
    props: {
        payData: {
            type: Object,
            default: () => ({}),
        },
        summaryData: {
            type: Object,
            default: () => ({}),
        },
        activityStatus: {
            type: Number,
            default: 0,
        },
        activityType: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            DEPOSIT_TYPE,
            DEPOSIT_TYPE_DESC,
            ACTIVITY_TYPE,
            YIYUANBAO_ACTIVITY_STATUS,
            QISHOUBAO_ACTIVITY_STATUS,
        };
    },
    computed: {
        priceUnit() {
            return PRICE_UNIT[this.summaryData.areaType || AREA_TYPE.Japan];
        },
        transferData() {
            const {
                depositFee,
                depositRefundFee,
                originFee,
                needPayFee,
                reducedFee,
                manualRefundFee,
                actualFee,
                lendPeriod,
                chargingPeriod,
                couponType = '{}',
                source,
                // couponSource,
                // couponName,
                // kaName,
                cnyDepositFee,
                cnyDepositRefundFee,
                cnyOriginFee,
                cnyNeedPayFee,
                cnyManualRefundFee,
                cnyActualFee,
                // rightsBizType,
                // rightsName,
            } = this.payData;
            const couponTypeJson = JSON.parse(couponType);
            // let couponSourceName = couponTypeJson?.code === 100 ? '次卡购买' : '';

            // if (!rightsBizType || rightsBizType === RIGHTS_TYPE.COUPON) {
            // couponSourceName = couponSourceName || (couponSource === COUPON_SOURCE.KA ? `${kaName}${couponName}` : '-');
            // } else {
            // couponSourceName = RIGHTS_INFO[rightsBizType] ? rightsName : '-';
            // }

            return {
                ...this.payData,
                depositFee: utils.formatPrice(depositFee),
                depositRefundFee: utils.formatPrice(depositRefundFee),
                originFee: utils.formatPrice(originFee),
                needPayFee: utils.formatPrice(needPayFee),
                reducedFee: utils.formatPrice(reducedFee),
                manualRefundFee: utils.formatPrice(manualRefundFee),
                actualFee: utils.formatPrice(actualFee),
                lendPeriod: utils.formatDuring(lendPeriod),
                chargingPeriod: utils.formatDuring(chargingPeriod),
                cnyDepositFee: utils.formatPrice(cnyDepositFee),
                cnyDepositRefundFee: utils.formatPrice(cnyDepositRefundFee),
                cnyOriginFee: utils.formatPrice(cnyOriginFee),
                cnyNeedPayFee: utils.formatPrice(cnyNeedPayFee),
                cnyManualRefundFee: utils.formatPrice(cnyManualRefundFee),
                cnyActualFee: utils.formatPrice(cnyActualFee),
                couponType: (couponTypeJson && couponTypeJson.value) ? couponTypeJson.value : '-',
                source: SOURCE_MAP[source] || '-',
                // couponSourceName,
            };
        },
    },
};
</script>

<style lang="less" scoped>
  .pay-info-comp {
    width: 100%;
    .comp-title {
      margin-bottom: 10px;
      font-size: 16px;
      color: #000;
    }
    .mtd-col {
      margin: 5px 0;
    }
  }
</style>
