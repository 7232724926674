import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import agentList from '../views/agent/list.vue';
import agentCreate from '../views/agent/create.vue';
import userList from '../views/agent/user.vue';
import loginPage from '../views/login/index.vue';
import permitList from '../views/permit/index.vue';
import poiAccountList from '../views/poi/account.vue';
import poiDetail from '../views/poi/detail.vue';
import poiList from '../views/poi/list.vue';
import typeList from '../views/poi/type.vue';
import orderList from '../views/order/list.vue';
import orderDetail from '../views/order/detail.vue';
import assetManagement from '../views/device/assetManagement.vue';
import benefitIndex from '../views/settlement/benefit/index.vue';
import cashOutLog from '../views/settlement/cashOut/list.vue';
import downloadPage from '../views/download/index.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/login',
        component: loginPage,
        name: 'loginPage',
        meta: {
            needLogin: false,
        },
    },
    {
        path: '/downloadapp',
        component: downloadPage,
        name: 'downLoadApp',
        meta: {
            needLogin: false,
        },
    },
    {
        path: '/agent/list',
        component: agentList,
        name: 'agentList',
    },
    {
        path: '/agent/create',
        component: agentCreate,
        name: 'agentCreate',
    },
    {
        path: '/user/list',
        component: userList,
        name: 'userList',
    },
    {
        path: '/permit/list',
        component: permitList,
        name: 'permitList',
    },
    {
        path: '/account/list',
        component: poiAccountList,
        name: 'poiAccountList',
    },
    {
        path: '/order/list',
        component: orderList,
        name: 'orderList',
    },
    {
        path: '/order/detail/:orderId/:userId',
        component: orderDetail,
        name: 'orderDetail',
    },
    {
        path: '/poi/list',
        component: poiList,
        name: 'poiList',
    },
    {
        path: '/poi/detail',
        component: poiDetail,
        name: 'poiDetail',
    },
    {
        path: '/poi/type',
        component: typeList,
        name: 'typeList',
    },
    {
        path: '/asset/management',
        component: assetManagement,
        name: 'assetManagement',
    },
    {
        path: '/benefit/index',
        component: benefitIndex,
        name: 'benefitIndex',
    },
    {
        path: '/cashout/log',
        component: cashOutLog,
        name: 'cashOutLog',
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;
